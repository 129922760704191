import { PartialOfferLineItem, PdfOfferLineItem } from '../../types';

export const convertPdfOfferLineItemToPartialOfferLineItem = (offer: PdfOfferLineItem): PartialOfferLineItem => ({
    rowId: offer.rowId,
    currency: offer.currency.value,
    unitPrice: offer.unitPrice.value,
    moq: offer.moq.value,
    mpq: offer.mpq.value,
    packaging: offer.packaging.value,
    standardFactoryLeadTime: offer.standardFactoryLeadTime.value,
    notes: offer.notes.value ?? '',
    stock: offer.stock.value,
    ncnr: offer.ncnr.value,
    part: offer.part.value,
    reach: offer.reach.value,
    rohs: offer.rohs.value,
    supplierPartNumber: offer.supplierPartNumber.value,
});
