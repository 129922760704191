import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface IcHubCredentialsDTO extends z.infer<typeof IcHubCredentialsDTORuntype> {}
export const IcHubCredentialsDTORuntype = z.object({
    api_key: z.string(),
    username: z.string(),
    password: z.string(),
});

export interface IcHubCredentialsInputDTO extends z.infer<typeof IcHubCredentialsInputDTORuntype> {}
export const IcHubCredentialsInputDTORuntype = z.object({
    api_key: z.string().optional(),
    username: z.string().optional(),
    password: z.string().optional(),
});

export interface IcHubResponseBodyDTO extends z.infer<typeof IcHubResponseBodyDTORuntype> {}
export const IcHubResponseBodyDTORuntype = z.object({
    data: IcHubCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
