import { Trans, t } from '@lingui/macro';
import { Currency, formatDecimal, formatMonetaryValue } from '@luminovo/commons';
import {
    Column,
    DataTable,
    DestructiveSecondaryIconButton,
    FieldNumericControlled,
    useDataTableState,
} from '@luminovo/design-system';
import { AvailabilityType, CustomPartPriceBreakDTO } from '@luminovo/http-client';
import { formatAvailability } from '@luminovo/sourcing-core';
import { Delete } from '@mui/icons-material';
import { InputAdornment, TableCell } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { CustomPartQuoteFormValues } from '../types';

type SharedContext = {
    offerIndex: number;
    currency: Currency;
    onRemove(index: number): void;
};

const columnWidth = (1024 - 80) / 3;

export function PriceBreaksTable({
    priceBreaks,
    offerIndex,
    mode,
    onRemove,
}: {
    priceBreaks: CustomPartPriceBreakDTO[];
    offerIndex: number;
    mode: 'view' | 'edit';
    onRemove(index: number): void;
}) {
    const columns = mode === 'view' ? columnsViewMode : columnsEditMode;

    const { control } = useFormContext<CustomPartQuoteFormValues>();
    const currency = useWatch({ control, name: 'currency' });
    const tableState = useDataTableState<CustomPartPriceBreakDTO, SharedContext>({
        persistenceId: `SupplierQuotePage::priceBreakTable:${mode}`,
        columns: columns,
        items:
            priceBreaks.length > 0
                ? priceBreaks
                : [
                      {
                          minimum_order_quantity: 0,
                          minimum_packaging_quantity: 1,
                          availability: { type: AvailabilityType.LeadTime, days: 0 },
                          price_per_measurement: {
                              amount: '',
                              currency,
                          },
                          description: null,
                      },
                  ],
        sharedContext: {
            offerIndex,
            currency,
            onRemove,
        },
        paginationOptions: {
            showPagination: false,
            defaultRowsPerPage: 1000,
            rowsPerPageOptions: [1000],
        },
    });

    return (
        <DataTable
            key={`SupplierQuotePage::priceBreakTable:${mode}:${priceBreaks.length}`}
            size="large"
            tableState={tableState}
        />
    );
}

const columnQuantity: Column<CustomPartPriceBreakDTO, SharedContext> = {
    id: 'quantity',
    label: <Trans>Quantity</Trans>,
    width: columnWidth,

    render: ({ data }) => <TableCell>{formatDecimal(data.minimum_order_quantity)}</TableCell>,
};

const columnMinimumPackagingQuantity: Column<CustomPartPriceBreakDTO, SharedContext> = {
    id: 'minimumPackagingQuantity',
    label: <Trans>MPQ</Trans>,
    width: columnWidth,

    render: ({ data }) => <TableCell>{formatDecimal(data.minimum_packaging_quantity)}</TableCell>,
};

const columnLeadTime: Column<CustomPartPriceBreakDTO, SharedContext> = {
    id: 'leadTime',
    label: <Trans>Lead time</Trans>,
    width: columnWidth,
    render: ({ data }) => <TableCell>{formatAvailability(data.availability)}</TableCell>,
};

const columnUnitPrice: Column<CustomPartPriceBreakDTO, SharedContext> = {
    id: 'unitPrice',
    label: <Trans>Unit price</Trans>,
    width: columnWidth,
    render: ({ data }) => <TableCell>{formatMonetaryValue(data.price_per_measurement, 'unit-price')}</TableCell>,
};

const columnsViewMode = [columnQuantity, columnMinimumPackagingQuantity, columnLeadTime, columnUnitPrice];

const columnEditQuantity: Column<CustomPartPriceBreakDTO, SharedContext> = {
    ...columnQuantity,
    render: function Render({ index: pricePointIndex }, { offerIndex }) {
        const { control } = useFormContext<CustomPartQuoteFormValues>();

        return (
            <TableCell>
                <FieldNumericControlled
                    required
                    min={1}
                    max={1_000_000_000}
                    FieldProps={{
                        fullWidth: true,
                        size: 'small',
                    }}
                    control={control}
                    name={`offers.${offerIndex}.price_breaks.${pricePointIndex}.minimum_order_quantity`}
                />
            </TableCell>
        );
    },
};

const columnEditMinimumPackagingQuantity: Column<CustomPartPriceBreakDTO, SharedContext> = {
    ...columnMinimumPackagingQuantity,
    render: function Render({ index: pricePointIndex }, { offerIndex }) {
        const { control } = useFormContext<CustomPartQuoteFormValues>();

        return (
            <TableCell>
                <FieldNumericControlled
                    required
                    min={1}
                    max={1_000_000_000}
                    FieldProps={{
                        fullWidth: true,
                        size: 'small',
                    }}
                    control={control}
                    name={`offers.${offerIndex}.price_breaks.${pricePointIndex}.minimum_packaging_quantity`}
                />
            </TableCell>
        );
    },
};

const columnEditLeadTime: Column<CustomPartPriceBreakDTO, SharedContext> = {
    ...columnLeadTime,
    render: function Render({ index: pricePointIndex }, { offerIndex }) {
        const { control } = useFormContext<CustomPartQuoteFormValues>();
        return (
            <TableCell>
                <FieldNumericControlled
                    min={1}
                    max={1_000_000}
                    FieldProps={{
                        fullWidth: true,
                        size: 'small',
                        InputProps: {
                            endAdornment: <InputAdornment position="end">{t`Days`}</InputAdornment>,
                        },
                    }}
                    control={control}
                    name={`offers.${offerIndex}.price_breaks.${pricePointIndex}.availability.days`}
                />
            </TableCell>
        );
    },
};

const columnEditUnitPrice: Column<CustomPartPriceBreakDTO, SharedContext> = {
    ...columnUnitPrice,
    render: function Render({ index: pricePointIndex }, { offerIndex }) {
        const { control } = useFormContext<CustomPartQuoteFormValues>();
        const currency = useWatch({ control, name: 'currency' });
        return (
            <TableCell>
                <FieldNumericControlled
                    FieldProps={{
                        fullWidth: true,
                        size: 'small',
                        InputProps: {
                            startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                        },
                    }}
                    required
                    exclusiveMin={0}
                    max={1_000_000_000}
                    control={control}
                    name={`offers.${offerIndex}.price_breaks.${pricePointIndex}.price_per_measurement.amount`}
                />
            </TableCell>
        );
    },
};

const columnActions: Column<CustomPartPriceBreakDTO, SharedContext> = {
    id: 'actions',
    label: '',
    render: function Render({ index: priceBreakIndex }, { onRemove }) {
        return (
            <TableCell>
                <DestructiveSecondaryIconButton
                    sx={{ visibility: priceBreakIndex > 0 ? 'visible' : 'hidden' }}
                    size="medium"
                    onClick={() => {
                        onRemove(priceBreakIndex);
                    }}
                >
                    <Delete fontSize="inherit" />
                </DestructiveSecondaryIconButton>
            </TableCell>
        );
    },
};

const columnsEditMode = [
    columnEditQuantity,
    columnEditMinimumPackagingQuantity,
    columnEditLeadTime,
    columnEditUnitPrice,
    columnActions,
];
