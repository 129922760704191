// https://stackoverflow.com/a/1026087
/**
 * Useful as the division bar (Vinculum) in equations.
 */
export const OVERSCORE_CHARACTER = '‾';

export function capitalizeFirstLetter(string: string): string {
    if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return string;
}

const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });

/**
 * @deprecated please use `compareByString` instead in `@luminovo/commons`
 */
export const alphaNumericalStringSort = (a: string, b: string): number => collator.compare(a, b);

export const getFileNameFromResourcesString = (resourceUrl: string): string => {
    const splitUrl = decodeURIComponent(resourceUrl.split('?sv=')[0]).split('/');
    const fileEncoded = splitUrl[splitUrl.length - 1];
    return decodeURI(fileEncoded);
};
