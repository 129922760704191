import { t } from '@lingui/macro';
import { colorSystem, MultiProgressBar } from '@luminovo/design-system';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormValuesQuoteRequest } from '../pages/supplier-portal/SupplierPortalQuoteRequestPage/types';
import { validateLineItem } from '../pages/supplier-portal/SupplierPortalQuoteRequestPage/validateLineItem';

export function IndicatorImportStatuses() {
    const { control } = useFormContext<FormValuesQuoteRequest>();
    const rows = useWatch({ control, name: 'rows' });

    let bid = 0;
    let noBid = 0;
    let bidLater = 0;
    let bidAtRisk = 0;
    let bidInvalid = 0;

    for (const row of rows) {
        if (!row.selected) {
            bidLater++;
        } else if (!row.bid) {
            noBid++;
        } else {
            const validationStatus = validateLineItem(row).status;
            if (validationStatus === 'success') {
                bid++;
            } else if (validationStatus === 'warning') {
                bidAtRisk++;
            } else if (validationStatus === 'error') {
                bidInvalid++;
            }
        }
    }

    return (
        <MultiProgressBar
            title="Status"
            //getBorder={(data) => (data.id === 'bidLater' ? `1px solid ${colorSystem.neutral[3]}` : undefined)}
            data={[
                { id: 'bid', label: t`Bid`, value: bid, color: colorSystem.green[5] },
                { id: 'bidAtRisk', label: t`Bid at risk`, value: bidAtRisk, color: colorSystem.yellow[5] },
                { id: 'bidInvalid', label: t`Bid invalid`, value: bidInvalid, color: colorSystem.red[5] },
                { id: 'no-bid', label: t`No-bid`, value: noBid, color: colorSystem.violet[5] },
                { id: 'bidLater', label: t`Bid later`, value: bidLater, color: colorSystem.neutral[5] },
            ]}
        />
    );
}
