import { useFirstLinkedPartFromComponent } from '@/resources/part/partHandler';
import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { CenteredLayout, useNavigate } from '@luminovo/design-system';
import {
    CustomFullPart,
    CustomLinkedPartDTO,
    CustomPartTypeEnum,
    PriceType,
    QuantityUnit,
    RfqContext,
} from '@luminovo/http-client';
import { CircularProgress } from '@mui/material';
import { useAttachmentFileMutation, useHttpFileUpload } from '../../../resources/http/useHttpFileUpload';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { useGlobalCurrency } from '../../../resources/organizationSettings/currencySettingsHandler';
import { useNonExcludedSupplierAndStockLocations } from '../../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { CustomPartOfferForm, CustomPartOfferFormValues } from './CustomPartOfferForm';
import { convertToCustomPartOfferInputDTO } from './converters';

export function AddCustomPartOfferForm({
    rfqId,
    linkedPart,
}: {
    rfqId: string;
    linkedPart: CustomLinkedPartDTO;
}): JSX.Element {
    const rfqContext: RfqContext = { type: 'WithinRfQ', rfq_id: rfqId };
    const { preferredCurrency } = useGlobalCurrency();
    const navigate = useNavigate();
    const { data: supplierAndStockLocations } = useNonExcludedSupplierAndStockLocations();
    const part: CustomFullPart | undefined = useFirstLinkedPartFromComponent(linkedPart);

    const { mutateAsync: mutateAsyncCustomPartOffer } = useHttpMutation('POST /offers/custom-part', {
        snackbarMessage: null,
    });

    const { mutateAsync: mutateUpload } = useHttpFileUpload(
        `GET /offers/custom-part/:id/additional-files/upload-link`,
        (response) => response.data.url,
        { snackbarMessage: null },
    );

    const { mutateAsync: mutateAttachmentFile } = useAttachmentFileMutation<{ offerId: string }>({
        mutateUpload: (file, { offerId }) => mutateUpload({ pathParams: { id: offerId }, files: file.file }),
        onSuccess: () => navigate(-1),
        snackbarMessage: t`Manual offer successfully created`,
    });

    if (!isPresent(supplierAndStockLocations)) {
        return (
            <CenteredLayout height={'30vh'}>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    const onSubmitCallback = async (values: CustomPartOfferFormValues) => {
        const requestBody = convertToCustomPartOfferInputDTO(values);
        const { data: offer } = await mutateAsyncCustomPartOffer({ requestBody });
        await mutateAttachmentFile({ context: { offerId: offer.id }, files: values.files });
    };

    const defaultSupplierAndStockLocation = supplierAndStockLocations[0];

    const defaultValues: CustomPartOfferFormValues = {
        linkedPart,
        priceType: PriceType.QuotePrice,
        supplierAndStockLocation: defaultSupplierAndStockLocation,
        currency: preferredCurrency,
        quantityUnit: {
            unit: QuantityUnit.Pieces,
            quantity: 1,
        },
        totalOfferedQuantity: undefined,
        priceBreaks: [
            {
                minimum_order_quantity: 1,
                minimum_packaging_quantity: 1,
                amount: '0',
                lead_time_days: undefined,
                description: undefined,
            },
        ],
        validUntilDate: undefined,
        oneTimeCosts: [],
        notes: undefined,
        sourcingBatchSize: undefined,
        files: [],
    };

    return (
        <CustomPartOfferForm
            isPanel={part ? part.type.name === CustomPartTypeEnum.PCB : false}
            rfqContext={rfqContext}
            onSubmit={onSubmitCallback}
            defaultValues={defaultValues}
        />
    );
}
