import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { MenuItem, useConfirmationDialog } from '@luminovo/design-system';
import { Menu } from '@mui/material';
import { Column, GridApi } from 'ag-grid-community';
import { useState } from 'react';
import { formatValue } from '../columns/getColumnValue';

export const MenuItemSetAllValues = <TRow, TValue>({
    column,
    api,
    options,
    onClose,
    onUpdateRow,
}: {
    column: Column<TValue>;
    api: GridApi<TRow>;
    options: Array<TValue>;
    onClose: () => void;
    onUpdateRow: (row: TRow, newValue: TValue) => TRow;
}): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const [newValue, setNewValue] = useState<{ value: TValue } | null>(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl((value) => (isPresent(value) ? null : event.currentTarget));
    };

    const { modal, setOpen } = useConfirmationDialog({
        title: t`Fill all cells with ${formatValue(newValue?.value, column)}`,
        description: t`Are you sure you want to fill all cells in this column with ${formatValue(newValue?.value, column)}?`,
        onConfirmation: async () => {
            const updatedRows: TRow[] = [];
            api.forEachNodeAfterFilterAndSort((node) => {
                const rowData = node.data;
                if (rowData && newValue) {
                    const updatedRowData = onUpdateRow(rowData, newValue.value);
                    updatedRows.push(updatedRowData);
                }
            });
            await api.applyTransactionAsync({ update: updatedRows });

            handleClose();
            onClose();
        },
    });

    if (column.getColDef().editable === false) {
        return <></>;
    }

    return (
        <>
            {modal}
            <MenuItem label={t`Fill all cells`} onClick={handleOpen} />
            <Menu
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                elevation={1}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {options.map((option) => (
                    <MenuItem
                        key={String(option)}
                        label={formatValue(option, column)}
                        onClick={() => {
                            setNewValue({ value: option });
                            setOpen(true);
                        }}
                    />
                ))}
            </Menu>
        </>
    );
};
