import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { IcHubCredentialsInputDTORuntype, IcHubResponseBodyDTORuntype } from './ichubBackendTypes';

export const ichubEndpoints = {
    'GET /3rdparty/ichub/credentials': endpoint({
        description: 'Returns credentials for icHub integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: IcHubResponseBodyDTORuntype,
    }),
    'POST /3rdparty/ichub/credentials': endpoint({
        description: 'Uploads credentials for icHub API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: IcHubCredentialsInputDTORuntype,
        responseBody: IcHubResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/ichub/credentials'],
    }),
    'PATCH /3rdparty/ichub/credentials': endpoint({
        description: 'Updates credentials for icHub API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: IcHubCredentialsInputDTORuntype,
        responseBody: IcHubResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/ichub/credentials'],
    }),
    'DELETE /3rdparty/ichub/credentials': endpoint({
        description: 'Deletes existing credentials for icHub API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/ichub/credentials'],
    }),
};
