import * as z from 'zod';

import { AssemblyTypeEnumRuntype } from '../assembly';
import { MonetaryValueBackendRuntype, QuantityUnitDTORuntype } from '../backendTypes';
import {
    ItemClassEnumRunType,
    PriceTypeRuntype,
    StandardPartOfferLinkedLocationType,
    StandardPartOfferLinkedLocationTypeRunType,
} from '../offer';
import { SiteRuntype } from '../organizationSettings';
import { PartLiteRuntype } from '../part';
import { SupplierDTORuntype } from '../supplier';
import { SupplierAndStockLocationDTORuntype } from '../supplierAndStockLocation';
import { UserDTORuntype } from '../user';

export enum QuoteRequestLineItemStatus {
    NotSent = 'NotSent', // not sent quote request
    Sent = 'Sent', // sent quote request
    Overdue = 'Overdue', // overdue quote request
    Received = 'Received', // received quote request with offer
    NoBid = 'NoBid', // received quote request, but no received offer
    Discarded = 'Discarded', // discarded quote request
    Awarded = 'Awarded', // Quote request line item's offer is in awarded award scenario
}

export enum QuoteRequestStatus {
    NotSent = 'NotSent',
    Sent = 'Sent',
    Overdue = 'Overdue',
    Received = 'Received',
    Discarded = 'Discarded',
}

export enum NegotiationLineItemStatus {
    NoQuoteRequests = 'NoQuoteRequests',
    NotSent = 'NotSent',
    Sent = 'Sent',
    PartiallyReceived = 'PartiallyReceived',
    Received = 'Received',
    NoBid = 'NoBid',
    Awarded = 'Awarded',
}

export enum QuoteRequestLineItemAssignmentReason {
    ManuallyAdded = 'ManuallyAdded',
    ReferenceSupplier = 'ReferenceSupplier',
    LineCard = 'LineCard',
    LineCardRule = 'LineCardRule',
}

export type QuoteRequestLineItemSummaryDTO = z.infer<typeof QuoteRequestLineItemSummaryDTORuntype>;
export const QuoteRequestLineItemSummaryDTORuntype = z.object({
    id: z.number(),
    quote_request_id: z.string(),
    assignment_reason: z.nativeEnum(QuoteRequestLineItemAssignmentReason),
    status: z.nativeEnum(QuoteRequestLineItemStatus),
});

export type PartOptionRequestSummaryDTO = z.infer<typeof PartOptionRequestSummaryDTORuntype>;
export const PartOptionRequestSummaryDTORuntype = z.object({
    negotiation_line_item_id: z.number(),
    part_option_id: z.number(),
    component_origin: z.string().nullable(),
    part: PartLiteRuntype,
    description: z.string().nullable(),
    quote_request_line_items: z.array(QuoteRequestLineItemSummaryDTORuntype),
});

export const QuoteRequestFieldRuntype = z.union([
    z.literal('Ipn'),
    z.literal('Description'),
    z.literal('Manufacturer'),
    z.literal('Mpn'),
    z.literal('Recipient'),
    z.literal('RequiredQuantity'),
    z.literal('PotentialQuantity'),
    z.literal('Unit'),
    z.literal('Attachment'),
    z.literal('Bid'),
    z.literal('OfferedMpn'),
    z.literal('OfferedManufacturer'),
    z.literal('UnitPrice'),
    z.literal('UnitPriceQuantity'),
    z.literal('UnitPriceCurrency'),
    z.literal('SupplierPartNumber'),
    z.literal('Packaging'),
    z.literal('MinimumOrderQuantity'),
    z.literal('MinimumPackagingQuantity'),
    z.literal('LeadTime'),
    z.literal('LeadTimeUnit'),
    z.literal('CancellationWindow'),
    z.literal('CancellationTimeUnit'),
    z.literal('ItemClass'),
    z.literal('Ncnr'),
    z.literal('Stock'),
    z.literal('ValidFrom'),
    z.literal('ValidUntil'),
    z.literal('AdditionalNotes'),
    z.literal('TargetPrice'),
    z.literal('Customer'),
    z.literal('NegotiatedByCustomer'),
    z.literal('OneTimeCost'),
]);
export type QuoteRequestField = z.infer<typeof QuoteRequestFieldRuntype>;

export type QuoteRequestDTO = z.infer<typeof QuoteRequestDTORuntype>;
export const QuoteRequestDTORuntype = z.object({
    id: z.string(),
    negotiation_id: z.number().nullable(),
    number: z.number(),
    supplier: SupplierDTORuntype,
    created_at: z.string(),
    discarded: z.boolean(),
    due_date: z.string().nullable().optional(),
    received_date: z.string().nullable().optional(),
    sent_date: z.string().nullable().optional(),
    notes: z.string().nullable().optional(),
    line_item_count: z.number(),
    status: z.nativeEnum(QuoteRequestStatus),
    sent_by: z
        .object({
            id: z.string(),
            first_name: z.string(),
            last_name: z.string(),
            email: z.string(),
        })
        .nullable(),
    sent_to: z.array(
        z.object({
            id: z.number(),
            supplier_contact: z.string().nullable(),
            quote_request: z.string(),
            name: z.string().nullable(),
            email: z.string(),
        }),
    ),
    configuration: z
        .object({
            hidden_fields: z.array(QuoteRequestFieldRuntype),
            required_fields: z.array(QuoteRequestFieldRuntype),
        })
        .optional(),
});

export const EmailEventStatusRuntype = z.union([
    z.literal('Queued'),
    z.literal('Bounce'),
    z.literal('Click'),
    z.literal('Deferred'),
    z.literal('Delivered'),
    z.literal('Drop'),
    z.literal('Open'),
    z.literal('Processed'),
    z.literal('SpamReport'),
    z.literal('Unknown'),
    z.literal('Unsubscribe'),
]);
export type EmailEventStatus = z.infer<typeof EmailEventStatusRuntype>;

export type EmailHistoryEventDTO = z.infer<typeof EmailHistoryEventDTORuntype>;
export const EmailHistoryEventDTORuntype = z.object({
    id: z.string(),
    subject: z.string(),
    content: z.string(),
    sender_email: z.string(),
    recipients: z.array(z.string()),
    ccs: z.array(z.string()),
    sent_by: z.string().nullable(),
    created_at: z.string(),
    statuses: z.array(
        z.object({
            id: z.string(),
            status: EmailEventStatusRuntype.catch('Unknown'),
            created_at: z.string(),
            description: z
                .string()
                .optional()
                .nullable()
                .transform((val) => val ?? ''),
        }),
    ),
});

export type QuoteRequestLineItemDTO = z.infer<typeof QuoteRequestLineItemDTORuntype>;
export const QuoteRequestLineItemDTORuntype = z.object({
    id: z.number(),
    quote_request_id: z.string(),
    assignment_reason: z.nativeEnum(QuoteRequestLineItemAssignmentReason),
    customers: z.string().nullable().optional(), // Refactor that in the future
    recipients: z.string().nullable().optional(),
    negotiation_line_item_id: z.number().nullable(),
    description: z.string().nullable().optional(),
    target_price: MonetaryValueBackendRuntype.nullable().optional(),
    required_quantity: QuantityUnitDTORuntype,
    potential_quantity: QuantityUnitDTORuntype,
    component_origin: z.string().nullable().optional(),
    requested_part: PartLiteRuntype.nullable().optional(),
    received_offer: z
        .object({
            offer_id: z.object({
                kind: z.union([z.literal('OffTheShelf'), z.literal('Custom')]),
                id: z.string(),
            }),
            part: PartLiteRuntype,
            linked_location_id: z.object({
                type: StandardPartOfferLinkedLocationTypeRunType,
                id: z.string(),
            }),
            unit_price: MonetaryValueBackendRuntype,
            moq: z.number(),
            mpq: z.number(),
            cancellation_window_in_days: z.number().nullable(),
            valid_from: z.string().nullable(),
            valid_until: z.string().nullable(),
            item_class: ItemClassEnumRunType.nullable(),
        })
        .nullable(),
    status: z.nativeEnum(QuoteRequestLineItemStatus),
});

export type AwardedOfferDTO = z.infer<typeof AwardedOfferDTORuntype>;
const AwardedOfferDTORuntype = z.object({
    id: z.number(),
    award_scenario_id: z.number(),
    negotiation_line_item_id: z.number(),
    price_type: PriceTypeRuntype.nullable(),
    awarded_solution: z.object({
        offer_id: z.object({
            kind: z.enum(['OffTheShelf', 'Custom', 'Inventory']),
            id: z.string(),
        }),
        part: PartLiteRuntype,
        linked_location_id: z.object({
            type: StandardPartOfferLinkedLocationTypeRunType,
            id: z.string(),
        }),
        unit_price: MonetaryValueBackendRuntype,
        moq: z.number(),
        mpq: z.number(),
        factory_lead_time_days: z.number().nullable(),
        unit_price_original_currency: MonetaryValueBackendRuntype,
    }),
});

export type MarketAwardedOffer = z.infer<typeof MarketAwardedOfferRuntype>;
const MarketAwardedOfferRuntype = AwardedOfferDTORuntype.extend({
    awarded_solution: AwardedOfferDTORuntype.shape.awarded_solution.extend({
        linked_location: SupplierAndStockLocationDTORuntype.extend({
            type: z.literal(StandardPartOfferLinkedLocationType.SupplierAndStockLocation),
        }),
    }),
});

export type InventoryAwardedOffer = z.infer<typeof InventoryAwardedOfferRuntype>;
const InventoryAwardedOfferRuntype = AwardedOfferDTORuntype.extend({
    awarded_solution: AwardedOfferDTORuntype.shape.awarded_solution.extend({
        linked_location: SiteRuntype.extend({
            type: z.literal(StandardPartOfferLinkedLocationType.InventorySite),
        }),
    }),
});

export type AwardedOffer = z.infer<typeof AwardedOfferRuntype>;
export const AwardedOfferRuntype = z.union([MarketAwardedOfferRuntype, InventoryAwardedOfferRuntype]);

export type AwardScenarioDTO = z.infer<typeof AwardScenarioDTORuntype>;
export const AwardScenarioDTORuntype = z.object({
    id: z.number(),
    name: z.string(),
    negotiation_id: z.number(),
    created_at: z.string(),
    is_automatic: z.boolean(),
    kind: z.union([
        z.object({ tag: z.literal('Awarded') }),
        z.object({ tag: z.literal('Manual') }),
        z.object({ tag: z.literal('BestListPrice') }),
        z.object({ tag: z.literal('BestPriceAcrossAllQuotes') }),
        z.object({ tag: z.literal('LastPurchasePrice') }),
        z.object({ tag: z.literal('LastStandardPrice') }),
        z.object({ tag: z.literal('LastTargetPrice') }),
        z.object({
            tag: z.literal('QuoteRequest'),
            data: z.number(), // for QuoteRequestId
        }),
    ]),
    awarded_offers: z.array(AwardedOfferDTORuntype),
});

export enum NegotiationCategory {
    Project = 'Project',
    Strategic = 'Strategic',
}
export type NegotiationDTO = z.infer<typeof NegotiationDTORuntype>;
export const NegotiationDTORuntype = z.object({
    id: z.number(),
    name: z.string(),
    created_at: z.string(),
    created_by: UserDTORuntype.nullable(),
    category: z.union([
        z.object({
            kind: z.literal(NegotiationCategory.Project),
            rfq_id: z.string(),
            rfq_name: z.string(),
        }),
        z.object({ kind: z.literal(NegotiationCategory.Strategic) }),
    ]),
});

export type NegotiationLineItemDTO = z.infer<typeof NegotiationLineItemRuntype>;
export const NegotiationLineItemRuntype = z.object({
    id: z.number(),
    negotiation_id: z.number(),
    quantity: QuantityUnitDTORuntype,
    parts: z.array(
        z.object({
            id: z.number(), // part option id
            part: PartLiteRuntype,
        }),
    ),
    awarded_offers: z.array(AwardedOfferDTORuntype),
    demands: z.array(z.string()), // ids of the demands
});

export type NegotiationLineItem = Omit<NegotiationLineItemDTO, 'awarded_offers' | 'demands'> & {
    awarded_offers: AwardedOffer[];
    demands: DemandDTO[];
};

// Demand

export type DemandDTO = z.infer<typeof DemandDTORuntype>;
export const DemandDTORuntype = z.object({
    id: z.string(),
    context: z.string().nullable(),
    component_ipn_id: z.string().nullable().optional(),
    created_at: z.string(),
    quantity: QuantityUnitDTORuntype,
    delivery_start_date: z.string().nullable(),
    delivery_end_date: z.string().nullable(),
    customer: z
        .object({
            id: z.string(),
            name: z.string(),
        })
        .nullable()
        .optional(),
    supplier_site: z
        .object({
            id: z.string(),
            name: z.string(),
        })
        .nullable()
        .optional(),
    ship_to_site: z
        .object({
            id: z.string(),
            name: z.string(),
        })
        .nullable()
        .optional(),
    assembly: z
        .object({
            id: z.string(),
            name: z.string(),
            assembly_type: z.object({
                type: AssemblyTypeEnumRuntype,
            }),
        })
        .nullable()
        .optional(),
});

export type QuoteRequestLineItemAggregatedStatusesDTO = z.infer<typeof QuoteRequestLineItemAggregatedStatusesRuntype>;
export const QuoteRequestLineItemAggregatedStatusesRuntype = z.object({
    overview: z.array(
        z.object({
            status: z.nativeEnum(QuoteRequestLineItemStatus),
            count: z.number(),
        }),
    ),
    statuses_by_negotiation_line_item: z.array(
        z.object({
            negotiation_line_item_id: z.number(),
            statuses: z.array(
                z.object({
                    status: z.nativeEnum(QuoteRequestLineItemStatus),
                    count: z.number(),
                }),
            ),
        }),
    ),
});

export type SupplierSelectionStrategyDTO = z.infer<typeof SupplierSelectionStrategyRuntype>;
export const SupplierSelectionStrategyRuntype = z.union([
    z.object({
        type: z.literal('SupplierPartTypeMatches'),
    }),
    z.object({
        type: z.literal('SupplierLineCard'),
        part_option_selection_strategy: z.union([z.literal('OnlyMatchingPartOptions'), z.literal('AllPartOptions')]),
        ignore_empty_line_card_supplier_matches: z.boolean(),
        include_empty_ipns: z.boolean(),
        include_generic_part_options: z.boolean(),
        include_raw_specification_parts: z.boolean(),
    }),
    z.object({
        type: z.literal('ApprovedVendorList'),
        part_option_selection_strategy: z.union([z.literal('OnlyMatchingPartOptions'), z.literal('AllPartOptions')]),
        ignore_empty_approved_vendor_list: z.boolean(),
        include_empty_ipns: z.boolean(),
        include_generic_part_options: z.boolean(),
        include_raw_specification_parts: z.boolean(),
    }),
    z.object({
        type: z.literal('ReferenceSupplier'),
        award_scenario_id: z.number(),
    }),
]);
