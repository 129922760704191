import * as z from 'zod';

// Please keep this in sync with https://gitlab.com/luminovo/product/epibator/-/blob/master/rust-workspace/domain-core/src/sources_api.rs#L22

export const sourcesApiValues = [
    'Alba',
    'APCT',
    'Arrow',
    'AvnetAbacus',
    'AvnetApac',
    'AvnetEbv',
    'AvnetSilica',
    'AvnetUsa',
    'AlElektronik',
    'BetaLayout',
    'Blume',
    'Buerklin',
    'Digikey',
    'Element14',
    'Eve',
    'Farnell',
    'Future',
    'Gatema',
    'Gudeco',
    'Heilind',
    'IBRRingler',
    'IcHub',
    'Lcsc',
    'Master',
    'Mouser',
    'MultiCB',
    'MyArrow',
    'Newark',
    'Nexar',
    'Octopart',
    'Onlinecomponents',
    'Corestaff',
    'WinSource',
    'Orbweaver',
    'RsComponents',
    'Rochester',
    'ChipCart',
    'Rutronik',
    'SafePcb',
    'Samtec',
    'Schukat',
    'Sos',
    'Sourcengine',
    'Stackrate',
    'Sluicebox',
    'Ti',
    'Tme',
    'TrustedParts',
    'Tti',
    'Venkel',
    'QuestComponents',
    'Weltron',
    'Wuerth',
    'WuerthEisos',
] as const;

export const SourcesApiRuntype = z.enum(sourcesApiValues);

export const ApiRelevanceRuntype = z.object({
    api: SourcesApiRuntype,
    relevant: z.boolean(),
});

export type ApiRelevanceDTO = z.infer<typeof ApiRelevanceRuntype>;
export type SourcesApiDTO = z.infer<typeof SourcesApiRuntype>;
