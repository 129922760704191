import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { PcbAlertRuntype } from './pcbAlertBackendTypes';
import {
    PcbApprovalStateRuntype,
    PcbEmissionsResponseRuntype,
    PcbErrorRuntype,
    PcbServerErrorType,
    PCBV2Runtype,
    PCBV2SpecificationCapabilitiesRuntype,
    PCBV2SpecificationRuntype,
    PCBV2SpecificationUpdateRequest,
    PCBV2UpdateRuntype,
} from './pcbBackendTypes';
import { ExtractionResultsPostRuntype, ExtractionResultsRuntype } from './pcbExtractionResultBackendTypes';
import {
    ExistingPanelPostRuntype,
    PanelDetailsPostRuntype,
    PanelPatchRuntype,
    PanelPostRuntype,
    PanelRuntype,
    PerPcbPanelPostRuntype,
    PerSourcingScenarioPanelPostRuntype,
} from './pcbPanelBackendTypes';
import { PCBGraphicsArray, PCBGraphicsResponse, PCBGraphicsResponseRuntype } from './pcbSvgBackendTypes';

export const pcbEndpoints = {
    'POST /ems/pcb/v2/pcbs': endpoint({
        description: 'Creates a new PCB',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            orderId: z.string(),
        }),
        responseBody: PCBV2Runtype,
    }),

    'GET /ems/pcb/v2/pcbs/:pcbId': endpoint({
        description: 'Find a PCB by ID',
        pathParams: z.object({ pcbId: z.string() }),
        requestBody: z.undefined(),
        queryParams: z.undefined(),
        responseBody: PCBV2Runtype,
    }),

    'GET /ems/pcb/v2/pcbs/:pcbId/capabilities': endpoint({
        description: 'The list of allowed values and setting for every field in the PCB.',
        pathParams: z.object({ pcbId: z.string() }),
        requestBody: z.undefined(),
        queryParams: z.undefined(),
        responseBody: PCBV2SpecificationCapabilitiesRuntype,
    }),

    'PUT /ems/pcb/v2/pcbs/:pcb/specifications/:specification': endpoint({
        description: 'Updates a PCB specification',
        pathParams: z.object({ pcb: z.string(), specification: z.string() }),
        queryParams: z.undefined(),
        requestBody: PCBV2UpdateRuntype,
        responseBody: PCBV2SpecificationRuntype,
        invalidates: [
            'GET /ems/pcb/v2/pcbs/:pcbId/capabilities',
            'GET /ems/pcb/v2/pcbs/:pcbId',
            'GET /assemblies/:assemblyId/state',
            'POST /rfqs/:rfqId/customer-portal',
            'GET /custom-part-alerts/:partId',
            'GET /panels',
        ],
    }),

    'PUT /ems/pcb/v2/pcbs/:pcb/specifications/:specification/status': endpoint({
        description: 'Sets a PCB specification status',
        pathParams: z.object({ pcb: z.string(), specification: z.string() }),
        queryParams: z.object({ removeUndefined: z.boolean().optional() }),
        requestBody: PCBV2SpecificationUpdateRequest,
        responseBody: PCBV2SpecificationRuntype,
    }),

    'PATCH /pcb-sides': endpoint({
        description: 'Set PCB Sides for the assembly',
        pathParams: z.undefined(),
        requestBody: z.object({ pcb_sides: z.number() }),
        queryParams: z.object({ assembly_id: z.string() }),
        responseBody: z.null(),
    }),

    'GET /pcb-sides': endpoint({
        description: 'Get PCB Sides for the assembly',
        pathParams: z.undefined(),
        requestBody: z.undefined(),
        queryParams: z.object({ assembly_id: z.string() }),
        responseBody: z.object({ pcb_sides: z.number() }),
    }),

    'GET /pcb/:pcbId/graphics': endpoint({
        description: 'Get all the PCB Graphics.',
        pathParams: z.object({ pcbId: z.string() }),
        requestBody: z.undefined(),
        queryParams: z.undefined(),
        responseBody: PCBGraphicsResponseRuntype,
        handleResponse: async (response, _) => {
            if (response.ok) {
                const json = await response.json();

                const graphicResponse: PCBGraphicsResponse = {
                    status: 'ok',
                    data: json as PCBGraphicsArray,
                };

                return graphicResponse;
            }

            if (response.status === 404) {
                const json = await response.json();

                try {
                    const checked = PcbErrorRuntype.parse(json);
                    if (checked.error.type === PcbServerErrorType.MissingLayerstack) {
                        const errorResponse: PCBGraphicsResponse = {
                            status: 'error',
                            error: PcbServerErrorType.MissingLayerstack,
                        };
                        return errorResponse;
                    } else if (checked.error.type === PcbServerErrorType.MissingOutline) {
                        const errorResponse: PCBGraphicsResponse = {
                            status: 'error',
                            error: PcbServerErrorType.MissingOutline,
                        };
                        return errorResponse;
                    }
                } catch {
                    // do nothing
                }
            }

            throw Error(`Failed to GET /pcb/:pcbId/graphics: ${response.status}`);
        },
    }),

    'GET /ems/renderer/layerstacks/versions/:pcbId': endpoint({
        description: 'Get layerstack version',
        pathParams: z.object({ pcbId: z.string() }),
        requestBody: z.undefined(),
        queryParams: z.undefined(),
        responseBody: z.string().nullable(),
        handleResponse: async (response, _) => {
            if (response.ok) {
                return response.text();
            }
            if (response.status === 404) {
                return null;
            }
            throw Error(`Unexpected response status ${response.status}`);
        },
    }),

    'GET /ems/pcb/v2/pcbs/:pcbId/token': endpoint({
        description: 'Get websocket token',
        pathParams: z.object({ pcbId: z.string() }),
        requestBody: z.undefined(),
        queryParams: z.undefined(),
        responseBody: z.object({ authToken: z.string() }),
    }),

    'POST /panels': endpoint({
        description: 'Creates a new panel (used mostly for GlobalPanel)',
        pathParams: z.undefined(),
        requestBody: PerPcbPanelPostRuntype.or(ExistingPanelPostRuntype),
        queryParams: z.undefined(),
        responseBody: PanelRuntype,
        invalidates: ['GET /panels'],
    }),

    'PATCH /panels/:panelId': endpoint({
        description: 'Updates a panel (used mostly for GlobalPanel)',
        pathParams: z.object({
            panelId: z.string(),
        }),
        requestBody: PanelPatchRuntype,
        queryParams: z.undefined(),
        responseBody: PanelRuntype,
        invalidates: ['GET /panels'],
    }),

    'POST /panels/bulk': endpoint({
        description: 'Creates multiple panels (used mostly for PerSourcingScenario)',
        pathParams: z.undefined(),
        requestBody: z.object({
            inserts: z.array(PerSourcingScenarioPanelPostRuntype),
        }),
        queryParams: z.undefined(),
        responseBody: z.array(PanelRuntype),
        invalidates: ['GET /panels'],
    }),

    'GET /panels': endpoint({
        description: 'Gets the created panels',
        pathParams: z.undefined(),
        requestBody: z.undefined(),
        queryParams: z.object({
            pcb_id: z.string(),
        }),
        responseBody: z.array(PanelRuntype),
    }),

    'POST /panels/generate': endpoint({
        description: 'Get values',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            pcb_id: z.string(),
            amount: z.number(),
            panel_preferences_id: z.string().optional(),
        }),
        responseBody: PanelDetailsPostRuntype,
    }),

    'POST /pcb/panel/render': endpoint({
        description: 'Get panel renderer',
        pathParams: z.undefined(),
        queryParams: z.object({
            pcbWidth: z.number(),
            pcbHeight: z.number(),
        }),
        requestBody: z.object({
            panel_details: PanelPostRuntype,
        }),
        responseBody: z.string().nullable(),
        handleResponse: async (response, _) => {
            if (response.ok) {
                return response.text();
            }
            if (response.status === 404) {
                return null;
            }
            throw Error(`Unexpected response status ${response.status}`);
        },
    }),

    'DELETE /panels/:panelId': endpoint({
        description: 'Deletes a panel',
        pathParams: z.object({
            panelId: z.string(),
        }),
        requestBody: z.undefined(),
        queryParams: z.undefined(),
        responseBody: z.object({
            deleted: z.number(),
        }),
        invalidates: ['GET /panels'],
    }),

    'GET /assemblies/:assemblyId/pcb/:pcbId/emissions': endpoint({
        description: 'Gets the emissions range for a PCB',
        pathParams: z.object({
            assemblyId: z.string(),
            pcbId: z.string(),
        }),
        requestBody: z.undefined(),
        queryParams: z.undefined(),
        responseBody: PcbEmissionsResponseRuntype,
        invalidates: ['GET /assemblies/:assemblyId/descendants-summary'],
    }),

    'GET /pcb-pdf-specification-extraction': endpoint({
        description: 'Gets the PDF extracted values',
        pathParams: z.undefined(),
        requestBody: z.undefined(),
        queryParams: z.object({
            pcb_id: z.string(),
        }),
        responseBody: ExtractionResultsRuntype.nullable(),
    }),

    'POST /pcb-pdf-specification-extraction': endpoint({
        description: 'Saves the PDF extracted values',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: ExtractionResultsPostRuntype,
        responseBody: ExtractionResultsRuntype,
        invalidates: ['GET /pcb-pdf-specification-extraction'],
    }),

    'DELETE /pcb-pdf-specification-extraction/:pcbId': endpoint({
        description: 'Deletes a pdf specification extraction',
        pathParams: z.object({ pcbId: z.string() }),
        requestBody: z.undefined(),
        queryParams: z.undefined(),
        responseBody: z.object({
            deleted: z.number(),
        }),
        invalidates: ['GET /pcb-pdf-specification-extraction'],
    }),

    'GET /pcb-approval-state': endpoint({
        description: 'Gets the approval state for a PCB',
        pathParams: z.undefined(),
        requestBody: z.undefined(),
        queryParams: z.object({ pcb_id: z.string() }),
        responseBody: PcbApprovalStateRuntype.nullable(),
    }),

    'PATCH /pcb-approval-state/:pcbId': endpoint({
        description: 'Updates the approval state for a PCB',
        pathParams: z.object({ pcbId: z.string() }),
        requestBody: PcbApprovalStateRuntype,
        queryParams: z.undefined(),
        responseBody: PcbApprovalStateRuntype,
        invalidates: [
            'GET /pcb-approval-state',
            'GET /assemblies/:assemblyId/state',
            'POST /assemblies/:id/pcb/:pcbId/offer-state',
        ],
    }),

    'GET /custom-part-alerts/:partId': endpoint({
        description: 'Gets the custom part alerts for a custom part',
        pathParams: z.object({ partId: z.string() }),
        requestBody: z.undefined(),
        queryParams: z.undefined(),
        responseBody: z.array(PcbAlertRuntype),
    }),

    'POST /custom-part-alerts/dismiss/:alertId': endpoint({
        description: 'Dismisses a custom part alert',
        pathParams: z.object({ alertId: z.string() }),
        requestBody: z.undefined(),
        queryParams: z.undefined(),
        responseBody: z.null(),
        invalidates: ['GET /custom-part-alerts/:partId'],
    }),
};
