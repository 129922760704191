import { t } from '@lingui/macro';
import { formatDecimal, formatPercentage } from '@luminovo/commons';
import { Box, Skeleton } from '@mui/material';
import { colorSystem } from '../../theme';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';

type MultiProgressBarProps<
    T extends { id: string; label: string; value: number; color: string; isSelected?: boolean },
> = {
    title?: string;
    data: T[];
    showTotalCount?: boolean;
    showPercentages?: boolean;
    showLegend?: boolean;
    getColor?: (data: T) => string;
    getLabel?: (data: T) => string;
    getValue?: (data: T) => number;
    getBorder?: (data: T) => string | undefined;
    isLoading?: boolean;
    onClick?: (data: T) => void;
    legendType?: 'vertical' | 'horizontal';
};

export function MultiProgressBar<
    T extends { id: string; label: string; value: number; color: string; isSelected?: boolean },
>({
    title = t`Progress`,
    data,
    showTotalCount = false,
    showPercentages = false,
    showLegend = true,
    getColor = (data) => data.color,
    getLabel = (data) => data.label,
    getValue = (data) => data.value,
    getBorder = (data) => undefined,
    isLoading,
    onClick,
    legendType = 'horizontal',
}: MultiProgressBarProps<T>): JSX.Element {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                overflow: 'hidden',
                width: showLegend ? 'auto' : '100%',
            }}
        >
            {title && (
                <Text variant="h5" color={colorSystem.neutral[7]}>
                    {title}
                </Text>
            )}
            {showTotalCount && (
                <Text variant="h1" color={colorSystem.neutral[9]}>
                    {isLoading ? (
                        <Skeleton width={100} />
                    ) : (
                        formatDecimal(data.reduce((acc, item) => acc + Number(getValue(item)), 0))
                    )}
                </Text>
            )}

            {isLoading ? (
                <Skeleton variant="rounded" width="90%" height={20} />
            ) : (
                <Box sx={{ display: 'flex', gap: 0.5, borderRadius: 0.5, overflow: 'hidden', flexDirection: 'row' }}>
                    {data
                        .filter((item) => item.value > 0)
                        .map((item, index) => {
                            const flex = getValue(item);
                            const selectedStyles: React.CSSProperties = {
                                border: `2px solid ${colorSystem.primary[5]}`,
                            };

                            return (
                                <Tooltip key={index} title={getLabel(item)}>
                                    <Box
                                        sx={{
                                            flex: flex,
                                            backgroundColor: getColor(item),
                                            border: getBorder(item),
                                            height: 20,
                                            borderRadius: 0.5,
                                            boxSizing: 'border-box',
                                            cursor: onClick ? 'pointer' : 'default',
                                            ...(item.isSelected ? selectedStyles : {}),
                                        }}
                                        onClick={() => onClick && onClick(item)}
                                    />
                                </Tooltip>
                            );
                        })}
                </Box>
            )}

            {showLegend && (
                <Box
                    sx={{
                        display: 'flex',
                        gap: 1,
                        alignItems: legendType === 'horizontal' ? 'center' : 'flex-start',
                        flexDirection: legendType === 'horizontal' ? 'row' : 'column',
                    }}
                >
                    {data.map((item, index) => {
                        return (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    gap: 1,
                                    alignItems: 'center',
                                    ...(legendType === 'horizontal' ? {} : { width: '100%' }),
                                }}
                            >
                                <div
                                    style={{
                                        borderRadius: 4,
                                        backgroundColor: getColor(item),
                                        border: getBorder(item),
                                        height: 12,
                                        width: 12,
                                        cursor: onClick ? 'pointer' : 'default',
                                        flexShrink: 0,
                                    }}
                                    onClick={() => onClick && onClick(item)}
                                />
                                <Text
                                    showEllipsis
                                    variant="body-small"
                                    color={colorSystem.neutral[7]}
                                    style={{
                                        cursor: onClick ? 'pointer' : 'default',
                                        ...(legendType === 'vertical' ? { flex: 1 } : {}),
                                    }}
                                    onClick={() => onClick && onClick(item)}
                                >
                                    {isLoading ? <></> : getLabel(item)}
                                </Text>
                                <Text
                                    variant="body-small"
                                    color={colorSystem.neutral[7]}
                                    style={{
                                        marginRight: legendType === 'horizontal' ? 20 : 0,
                                        cursor: onClick ? 'pointer' : 'default',
                                        flexShrink: 0,
                                    }}
                                    onClick={() => onClick && onClick(item)}
                                >
                                    {isLoading ? (
                                        <Skeleton width={100} />
                                    ) : (
                                        <>
                                            {getValue(item)}
                                            {showPercentages &&
                                                ` (${formatPercentage(getValue(item) / data.reduce((acc, d) => acc + getValue(d), 0))})`}
                                        </>
                                    )}
                                </Text>
                            </Box>
                        );
                    })}
                </Box>
            )}
        </Box>
    );
}
