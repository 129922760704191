import { useFirstLinkedPartFromComponent } from '@/resources/part/partHandler';
import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { CenteredLayout, useNavigate } from '@luminovo/design-system';
import { AvailabilityType, CustomFullPart, CustomPartTypeEnum, PriceType, RfqContext } from '@luminovo/http-client';
import { CircularProgress } from '@mui/material';
import { useAttachmentFileMutation, useHttpFileUpload } from '../../../resources/http/useHttpFileUpload';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { useCustomPartOffer } from '../../../resources/offer/offerHandler';
import { useGlobalCurrency } from '../../../resources/organizationSettings/currencySettingsHandler';
import { useSupplierAndStockLocations } from '../../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { nullToUndefined } from '../../../utils/typingUtils';
import { convertAzureFilesToUploadFiles } from '../../StandardPartOfferForm.tsx/components/converters';
import { CustomPartOfferForm, CustomPartOfferFormValues } from './CustomPartOfferForm';
import { convertToCustomPartOfferUpdateDTO } from './converters';
export function EditCustomPartOfferForm({ rfqId, offerId }: { rfqId: string; offerId: string }): JSX.Element {
    const rfqContext: RfqContext = { type: 'WithinRfQ', rfq_id: rfqId };
    const navigate = useNavigate();
    const { data: offer } = useCustomPartOffer(offerId);
    const { data: supplierAndStockLocations } = useSupplierAndStockLocations();
    const { preferredCurrency } = useGlobalCurrency();
    const { data: files } = useHttpQuery(
        'GET /offers/custom-part/:id/additional-files',
        { pathParams: { id: offerId } },
        {
            select: (res) => res.items,
        },
    );
    let part: CustomFullPart | undefined = useFirstLinkedPartFromComponent(offer?.linked_part);

    const { mutateAsync } = useHttpMutation('PATCH /offers/custom-part/:id', {
        snackbarMessage: null,
    });

    const { mutateAsync: mutateUpload } = useHttpFileUpload(
        `GET /offers/custom-part/:id/additional-files/upload-link`,
        (response) => response.data.url,
        { snackbarMessage: null },
    );
    const { mutateAsync: mutateDelete } = useHttpMutation(`DELETE /offers/custom-part/:id/additional-files`, {
        snackbarMessage: null,
    });

    const { mutateAsync: mutateAttachmentFile } = useAttachmentFileMutation<{ offerId: string }>({
        mutateUpload: (file, { offerId }) => mutateUpload({ pathParams: { id: offerId }, files: file.file }),
        mutateDelete: (file, { offerId }) =>
            mutateDelete({ pathParams: { id: offerId }, requestBody: { file_name: file.name } }),
        onSuccess: () => navigate(-1),
        snackbarMessage: t`Manual offer updated`,
    });

    if (!isPresent(offer) || !isPresent(supplierAndStockLocations) || !isPresent(files)) {
        return (
            <CenteredLayout height={'30vh'}>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    const onSubmitCallback = async (values: CustomPartOfferFormValues) => {
        const requestBody = convertToCustomPartOfferUpdateDTO(values);
        await mutateAsync({ pathParams: { id: offer.id }, requestBody });
        await mutateAttachmentFile({ context: { offerId: offer.id }, files: values.files });
    };

    const defaultValues: CustomPartOfferFormValues = {
        linkedPart: offer.linked_part,
        priceType: offer.price_type ?? PriceType.ContractPrice,
        supplierAndStockLocation: offer.linked_location,
        currency: offer.price_breaks[0].price_per_measurement.currency ?? preferredCurrency,
        quantityUnit: offer.unit_of_measurement.quantity_unit,
        priceBreaks: offer.price_breaks.map((p) => ({
            minimum_order_quantity: p.minimum_order_quantity,
            minimum_packaging_quantity: p.minimum_packaging_quantity,
            amount: p.price_per_measurement.amount,
            lead_time_days: p.availability.type === AvailabilityType.LeadTime ? p.availability.days : undefined,
            description: p.description ?? undefined,
        })),
        totalOfferedQuantity: nullToUndefined(offer.total_offered_quantity),
        validUntilDate: nullToUndefined(offer.valid_until),
        oneTimeCosts: offer.one_time_costs.map((c) => ({
            amount: c.price.amount,
            description: c.description ?? undefined,
        })),
        notes: offer.notes,
        sourcingBatchSize: offer.sourcing_batch_size,
        files: convertAzureFilesToUploadFiles(files),
    };

    return (
        <CustomPartOfferForm
            isPanel={part ? part.type.name === CustomPartTypeEnum.PCB : false}
            rfqContext={rfqContext}
            onSubmit={onSubmitCallback}
            showUnitOfMeasurementWarning={false}
            defaultValues={defaultValues}
        />
    );
}
