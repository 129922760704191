import { ViewCommentsButton } from '@/components/CommentsDrawer/ViewCommentsButton';
import { useCommunicationsDrawer } from '@/components/CommentsDrawer/useCommunicationsDrawer';
import { useHttpMutation } from '@/resources/mutation/useHttpMutation';
import { plural, t } from '@lingui/macro';
import { countBy, formatDecimal, formatRelativeTime, formatToLongDate, transEnum } from '@luminovo/commons';
import {
    colorSystem,
    createColumnHelper,
    DestructiveSecondaryIconButton,
    Flexbox,
    MultiProgressBar,
    NonIdealState,
    PrimaryButton,
    SupportedFilterFn,
    Tab,
    Table,
    Tabs,
    Tag,
    TanStackTable,
    Text,
    useConfirmationDialog,
    useNavigate,
    useTanStackTable,
} from '@luminovo/design-system';
import { QuoteRequestDTO, QuoteRequestLineItemStatus, QuoteRequestStatus } from '@luminovo/http-client';
import { formatSupplierDTO } from '@luminovo/sourcing-core';
import { Delete, EmailOutlined } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import { LayoutCard } from '../../../../components/LayoutCard';
import { PageLayoutCollapsibleSidebar } from '../../../../components/PageLayoutCollapsibleSidebar';
import { route, UrlParams } from '../../../../utils/routes';
import { ButtonConfigureQuoteRequests } from '../../../Negotiations/components/ButtonSendQuoteRequests';
import { getQuoteRequestLineItemStatusColor } from '../../../Negotiations/components/QuoteRequestLineItemStatusChip';
import {
    getQuoteRequestStatusColor,
    QuoteRequestStatusChip,
} from '../../../Negotiations/components/QuoteRequestStatusChip';
import {
    useNegotiationAggregatedStatuses,
    useNegotiationQuoteRequests,
} from '../../../Negotiations/hooks/negotiationHandlers';
import { quoteRequestLineItemStatusTranslations, quoteRequestStatusTranslations } from '../../../Negotiations/i18n';
import { NavigationSidebarSourcing } from '../NavigationSidebarSourcing';

function ChartQuoteRequestStatus({ negotiationId }: { negotiationId: number }) {
    const { data: quoteRequests, isLoading } = useNegotiationQuoteRequests(negotiationId);

    const statusCounts = countBy(quoteRequests, (q) => q.status);

    const quoteRequestData = Object.values(QuoteRequestStatus).map((status) => {
        return {
            id: status,
            label: transEnum(status, quoteRequestStatusTranslations),
            value: statusCounts[status] ?? 0,
            color: colorSystem[getQuoteRequestStatusColor(status)][5],
        };
    });

    return (
        <MultiProgressBar
            title={t`Quote requests`}
            showTotalCount
            isLoading={isLoading}
            data={quoteRequestData}
            getColor={(data) => data.color}
            getLabel={(data) => data.label}
            getValue={(data) => data.value}
        />
    );
}

function ChartQuoteRequestLineItemStatus({ negotiationId }: { negotiationId: number }) {
    const { data: aggregatedStatuses, isLoading } = useNegotiationAggregatedStatuses(negotiationId);

    const lineItemData = Object.values(QuoteRequestLineItemStatus)
        .filter((status) => status !== QuoteRequestLineItemStatus.Awarded)
        .map((status) => {
            const existingStatus = aggregatedStatuses?.overview.find((s) => s.status === status);
            return {
                id: status,
                label: transEnum(status, quoteRequestLineItemStatusTranslations),
                value: existingStatus?.count ?? 0,
                color: colorSystem[getQuoteRequestLineItemStatusColor(status)][5],
            };
        });

    return (
        <MultiProgressBar
            title={t`Quote request line items`}
            showTotalCount
            isLoading={isLoading}
            data={lineItemData}
            getColor={(data) => data.color}
            getLabel={(data) => data.label}
            getValue={(data) => data.value}
        />
    );
}

function CommentsButton({ quoteRequestId }: { quoteRequestId: string }) {
    const { openDrawer } = useCommunicationsDrawer({
        threads: [
            {
                commentType: 'QuoteRequest',
                category: 'Internal',
                typeIds: quoteRequestId,
            },
            {
                commentType: 'QuoteRequest',
                category: 'Public',
                typeIds: quoteRequestId,
            },
        ],
    });

    return (
        <ViewCommentsButton
            eventEntity={{ type: 'QuoteRequest', data: quoteRequestId }}
            onClick={() => openDrawer()}
            iconButtonOnly={true}
            size={'small'}
        />
    );
}

type SharedContext = {
    rfqId: string;
    negotiationId: number;
};

const columnHelper = createColumnHelper<QuoteRequestDTO>();
const columns = [
    columnHelper.text((row) => formatSupplierDTO(row.supplier), {
        id: 'supplier.name',
        label: () => t`Supplier`,
        size: 160,
    }),
    columnHelper.text('number', {
        id: 'quoteNumber',
        label: () => t`Quote number`,
        size: 80,
        renderType: 'generic',
        cell: (info) => <Tag attention="low" color={'neutral'} label={`#${info.getValue()}`} />,
    }),
    columnHelper.number((qr) => qr.line_item_count, {
        id: 'lineItems',
        size: 100,
        label: () => t`Line items`,
        cell: ({ row }) => formatDecimal(row.original.line_item_count),
    }),
    columnHelper.enum('status', {
        id: 'status',
        getOptionLabel: (status) => transEnum(status, quoteRequestStatusTranslations),
        quickFilters: [
            {
                label: () => t`Not sent`,
                value: { filterFn: SupportedFilterFn.equalsAny, value: [QuoteRequestStatus.NotSent] },
                showCount: true,
            },
            {
                label: () => 'Received',
                value: { filterFn: SupportedFilterFn.equalsAny, value: [QuoteRequestStatus.Received] },
                showCount: true,
            },
        ],
        label: () => t`Status`,
        size: 100,
        cell: ({ row }) => <QuoteRequestStatusChip status={row.original.status} />,
    }),
    columnHelper.date('created_at', {
        id: 'created_at',
        label: () => t`Created on`,
        size: 100,
        initialVisibility: false,
        cell: (item) => (
            <Tooltip title={formatToLongDate(item.getValue())}>
                <Text variant={'inherit'}>{formatRelativeTime(item.getValue())}</Text>
            </Tooltip>
        ),
    }),
    columnHelper.date('sent_date', {
        id: 'sent_date',
        label: () => t`Sent on`,
        size: 100,
        cell: (item) => (
            <Tooltip title={formatRelativeTime(item.getValue(), { ifAbsent: '' })}>
                <Text variant={'inherit'}>{formatToLongDate(item.getValue())}</Text>
            </Tooltip>
        ),
    }),
    columnHelper.text('sent_by.email', {
        label: () => t`Sent by`,
        size: 100,
        cell: (item) => item.getValue() ?? '-',
    }),
    columnHelper.text((row) => row.sent_to.map((recipient) => recipient.email), {
        id: 'sent_to',
        label: () => t`Sent to`,
        size: 100,
        cell: (item) => {
            const recipients = item.row.original.sent_to;
            if (recipients.length === 0) {
                return '-';
            }
            return recipients.map((recipient) => recipient.email).join(', ');
        },
    }),
    columnHelper.date('due_date', {
        id: 'due_date',
        label: () => t`Due date`,
        size: 100,
        cell: (item) => (
            <Tooltip title={formatRelativeTime(item.getValue(), { ifAbsent: '' })}>
                <Text variant={'inherit'}>{formatToLongDate(item.getValue())}</Text>
            </Tooltip>
        ),
    }),
    columnHelper.date('received_date', {
        id: 'received_date',
        label: () => t`Submitted date`,
        size: 100,
        cell: (item) => (
            <Tooltip title={formatRelativeTime(item.getValue(), { ifAbsent: '' })}>
                <Text variant={'inherit'}>{formatToLongDate(item.getValue())}</Text>
            </Tooltip>
        ),
    }),
    columnHelper.text(() => null, {
        id: 'comments',
        label: () => t`Comments`,
        size: 80,
        enableColumnFilter: false,
        enableSorting: false,
        enableOnRowClick: false,
        align: 'center',
        renderType: 'generic',
        cell: ({ row }) => <CommentsButton quoteRequestId={row.original.id} />,
    }),
];

function useTable({ rfqId, negotiationId }: { rfqId: string; negotiationId: number }) {
    const { data } = useNegotiationQuoteRequests(negotiationId);

    return useTanStackTable({
        data,
        columns,
        enableColumnHiding: true,
        enableColumnOrdering: true,
        enableSelection: {
            enabled: true,
            getRowId: (row) => String(row.id),
        },
        sharedContext: {
            rfqId,
            negotiationId,
        },
        enableRowSelection: (row) => row.original.status === QuoteRequestStatus.NotSent,
        getRowRoute: (row) =>
            route(
                '/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests/:quoteRequestId',
                {
                    rfqId,
                    negotiationId,
                    quoteRequestId: row.original.id,
                },
                {
                    highlightNegotiationLineItemId: null,
                },
            ),
    });
}

function ButtonSendQuoteRequests({
    quoteRequests,
    negotiationId,
    rfqId,
}: {
    quoteRequests: QuoteRequestDTO[];
    negotiationId: number;
    rfqId: string;
}) {
    const sendable = quoteRequests.filter((s) => s.status === QuoteRequestStatus.NotSent);

    return (
        <Tooltip
            title={plural(sendable.length, {
                zero: `Please select at least one quote request`,
                one: `Send quote request`,
                other: `Send ${formatDecimal(sendable.length)} quote requests`,
            })}
        >
            <span>
                <PrimaryButton
                    size="medium"
                    disabled={sendable.length === 0}
                    href={route(
                        '/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests/preview-email',
                        {
                            rfqId,
                            negotiationId,
                        },
                        {
                            // TODO: This might break when to many quote requests are selected because we store it in the URL
                            quoteRequestNumbers: sendable.map((s) => s.number).join(','),
                        },
                    )}
                >
                    {sendable.length > 1 ? t`Send ${formatDecimal(sendable.length)} requests` : t`Send request`}
                </PrimaryButton>
            </span>
        </Tooltip>
    );
}

function ButtonDeleteQuoteRequests({ quoteRequests }: { quoteRequests: QuoteRequestDTO[] }) {
    const { mutateAsync, isPending } = useHttpMutation('DELETE /quote-request/delete-many', {
        snackbarMessage: t`Successfully deleted quote requests`,
    });

    const { modal, setOpen } = useConfirmationDialog({
        title: t`Delete quote requests`,
        description: t`Are you sure you want to delete the selected quote requests?`,
        onConfirmation: () => mutateAsync({ requestBody: { quote_request_ids: quoteRequests.map((qr) => qr.id) } }),
    });

    return (
        <>
            {modal}
            <Tooltip
                title={plural(quoteRequests.length, {
                    zero: `Please select at least one quote request`,
                    one: `Delete quote request`,
                    other: `Delete ${formatDecimal(quoteRequests.length)} quote requests`,
                })}
            >
                <span>
                    <DestructiveSecondaryIconButton
                        size="medium"
                        isLoading={isPending}
                        onClick={() => setOpen(true)}
                        disabled={quoteRequests.length === 0}
                    >
                        <Delete fontSize="inherit" />
                    </DestructiveSecondaryIconButton>
                </span>
            </Tooltip>
        </>
    );
}

function ActionButton({ table, sharedContext }: { table: Table<QuoteRequestDTO>; sharedContext: SharedContext }) {
    const quoteRequests = table.getSelectedRowModel().rows.map((row) => row.original);
    return (
        <Flexbox gap={8}>
            <ButtonConfigureQuoteRequests quoteRequests={quoteRequests} />
            <ButtonDeleteQuoteRequests quoteRequests={quoteRequests} />
            <ButtonSendQuoteRequests
                quoteRequests={quoteRequests}
                negotiationId={sharedContext.negotiationId}
                rfqId={sharedContext.rfqId}
            />
        </Flexbox>
    );
}

function EmptyPlaceholder(): JSX.Element {
    return (
        <NonIdealState
            Icon={EmailOutlined}
            title={t`No quote requests found`}
            description={t`Create a quote request to get started`}
        />
    );
}

export default function SourcingNegotiationQuoteRequestsPage({
    pathParams,
}: UrlParams<'/rfqs/:rfqId/sourcing/negotiations/:negotiationId/line-items'>) {
    const { rfqId } = pathParams;
    const negotiationId = Number(pathParams.negotiationId);
    const navigate = useNavigate();

    const { table } = useTable({ rfqId, negotiationId });

    return (
        <PageLayoutCollapsibleSidebar sidebar={<NavigationSidebarSourcing rfqId={rfqId} />} layoutVariant="fullWidth">
            <Flexbox gap="20px" flexDirection="column" height="100%">
                <Tabs sx={{ minHeight: 32 }} size="large" value={'quoteRequests'}>
                    <Tab
                        value={'lineItems'}
                        label={t`Line items`}
                        onClick={() =>
                            navigate(
                                route('/rfqs/:rfqId/sourcing/negotiations/:negotiationId/line-items', {
                                    rfqId,
                                    negotiationId,
                                }),
                            )
                        }
                    />
                    <Tab
                        value={'quoteRequests'}
                        label={t`Quote requests`}
                        onClick={() =>
                            navigate(
                                route('/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests', {
                                    rfqId,
                                    negotiationId,
                                }),
                            )
                        }
                    />
                </Tabs>

                <LayoutCard title={undefined}>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            gap: 4,
                        }}
                    >
                        <ChartQuoteRequestStatus negotiationId={negotiationId} />
                        <ChartQuoteRequestLineItemStatus negotiationId={negotiationId} />
                    </Box>
                </LayoutCard>
                <LayoutCard title={t`Quote requests`} style={{ flexGrow: 1, marginBottom: 0, paddingBottom: '60px' }}>
                    <TanStackTable table={table} ActionButton={ActionButton} EmptyPlaceholder={EmptyPlaceholder} />
                </LayoutCard>
            </Flexbox>
        </PageLayoutCollapsibleSidebar>
    );
}
