import { assertUnreachable } from '@luminovo/commons';
import {
    CustomPartOfferResponse,
    CustomPartOfferResultType,
    DetailedCustomPartOffers,
    PCBV2,
    SimpleOffersDTO,
} from '@luminovo/http-client';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';

export const checkIfOfferIsPending = (offers: CustomPartOfferResponse): boolean => {
    switch (offers.type) {
        case CustomPartOfferResultType.Simple:
            return checkIfSimpleOfferIsPending(offers);
        case CustomPartOfferResultType.Detailed:
            return checkIfDetailedOffersIsPending(offers);

        case CustomPartOfferResultType.AnalysisInProgress:
            return true;

        case CustomPartOfferResultType.NoSourcingScenarios:
        case CustomPartOfferResultType.NoSuppliersConfigured:
        case CustomPartOfferResultType.NoDefinedQuantity:
        case CustomPartOfferResultType.NotApproved:
            return false;

        default:
            assertUnreachable(offers);
    }
};

const checkIfDetailedOffersIsPending = (offers: DetailedCustomPartOffers): boolean => {
    return offers.is_pending;
};

const checkIfSimpleOfferIsPending = (offers: SimpleOffersDTO): boolean => {
    return offers.is_pending || offers.data.type === 'Pending';
};

export const usePcbOffersState = ({ pcb, assemblyId }: { pcb: PCBV2; assemblyId: string }) => {
    return useHttpQuery(
        'POST /assemblies/:id/pcb/:pcbId/offer-state',
        {
            pathParams: {
                id: assemblyId,
                pcbId: pcb.id,
            },
        },
        {
            refetchInterval: ({ state }) => {
                if (!state.data) return false;

                if (
                    state.data.status.type === 'NoSourcingScenarios' ||
                    state.data.status.type === 'NoSuppliersConfigured'
                )
                    return false;

                if (state.data.status.type === 'AnalysisInProgress') return 2_000;

                if (checkIfOfferIsPending(state.data.status)) return 2_000;

                return false;
            },
        },
    );
};
