import { EChartProps } from '@kbox-labs/react-echarts';
import { Edit } from '@mui/icons-material';
import {
    ColDef,
    ColDefField,
    Column,
    GridApi,
    IHeaderParams,
    ValueFormatterFunc,
    ValueGetterFunc,
} from 'ag-grid-community';

export enum DataGridDefaultActions {
    Edit = 'edit',
    Delete = 'delete',
    View = 'view',
    Duplicate = 'duplicate',
    AddRow = 'addRow',
}

export interface DataGridRow {}

export interface DataGridRowSelectable extends DataGridRow {
    selected: boolean;
}

export interface DataGridRowWithActions extends DataGridRow {
    actions: Array<{
        icon?: typeof Edit;
        label: string;
        onClick: () => Promise<void> | void;
    }>;
}

export type AgGridFilter =
    | {
          filterType: 'text';
          type: 'contains' | 'notContains' | 'equals' | 'startsWith' | 'endsWith' | 'notEqual';
          filter: string;
      }
    | {
          filterType: 'text';
          type: 'blank' | 'notBlank';
      }
    | {
          filterType: 'number';
          type: 'greaterThanOrEqual' | 'lessThanOrEqual';
          filter: number;
      }
    | {
          filterType: 'number';
          type: 'inRange';
          filter: number;
          filterTo: number;
      };

// We might use these in the future, but for now we don't need them.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface QuickFilterProps<TRow extends DataGridRow = DataGridRow, TValue = any> {
    label: () => string;
    value: TValue;
    hideCount?: boolean;
    column: Column;
}

export interface DataGridColumn<TRow extends DataGridRow = DataGridRow, TValue = any> extends ColDef<TRow, TValue> {
    label?: () => string;
    valueGetter: ValueGetterFunc<TRow, TValue>;
    valueFormatter?: ValueFormatterFunc<TRow, TValue>;

    validate?: (
        value: TValue | null | undefined,
        row: TRow,
    ) => {
        status: 'success' | 'error' | 'warning';
        message?: string;
    };

    headerComponent?: (props: IHeaderParams) => React.ReactNode;

    cellRenderer?: (props: {
        value: TValue | null | undefined;
        setValue: (value: TValue | null | undefined) => void;
        data: TRow;
        api: GridApi;
        column: Column;
    }) => React.ReactNode;

    chart?: (
        data: TRow[],
        column: Column,
    ) => {
        xAxis: EChartProps['xAxis'];
        yAxis: EChartProps['yAxis'];
        series: EChartProps['series'];
    };

    /**
     * To use the quick filters, you need to set the filterValueGetter to a function that returns the value to filter on.
     */
    quickFilters?: Omit<QuickFilterProps<TRow, TValue>, 'column'>[];
}

/**
 * A column definition that with a mandatory value getter.
 */
export type DataGridBooleanColumn<TRow extends DataGridRow> = DataGridColumn<TRow, boolean> & {
    // this is needed to provide a global value setter, but it does make the data
    // model less flexible.
    field?: ColDefField<TRow, boolean>;
};

/**
 * A column definition that with a mandatory value getter.
 */
export type DataGridEnumColumn<TRow extends DataGridRow, TValue> = DataGridColumn<TRow, TValue> & {
    options?: TValue[];
    /**
     * A function that is called when the user bulk updates the value of the column using the set all values menu item.
     */
    onUpdateRow?: (row: TRow, newValue: TValue) => TRow;
};

export type DataGridAction =
    | {
          id: DataGridDefaultActions;
          onClick: () => Promise<void>;
      }
    | {
          label: string;
          onClick: () => Promise<void>;
      };
