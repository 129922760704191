import { t, Trans } from '@lingui/macro';
import { assertUnreachable, isPresent, uniqueBy } from '@luminovo/commons';
import {
    ButtonGroup,
    ButtonGroupItem,
    CenteredLayout,
    chainComparators,
    Checkbox,
    colorSystem,
    Comparable,
    compareByNumber,
    FieldText,
    Flexbox,
    SecondaryButton,
    TertiaryButton,
    Text,
} from '@luminovo/design-system';
import { SiteDTO } from '@luminovo/http-client';
import { formatInventorySite } from '@luminovo/sourcing-core';
import { Search, StarBorderRounded, StarRounded } from '@mui/icons-material';
import { Box, CircularProgress, InputAdornment } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FixedSizeList } from 'react-window';
import { useInventorySites } from '../../../resources/organizationSettings/sitesHandler';
import { SourcingScenarioFormValues } from './converters';

type DisplayMode = 'approved' | 'excluded' | 'notApproved';
type SelectionStatus = 'none' | 'all' | 'indeterminate';

function createComparatorByIndex(allInventorySites: SiteDTO[], preferredIds: string[]): Comparable<string> {
    const indexMap = new Map<string, number>();
    allInventorySites.forEach((obj, index) => {
        indexMap.set(obj.id, index);
    });
    const preferredIdsSet = new Set(preferredIds);
    return chainComparators(
        compareByNumber((item) => (preferredIdsSet.has(item) ? 0 : 1)),
        compareByNumber((item) => indexMap.get(item) ?? 0),
    );
}

function useWatchInventorySites() {
    const { control } = useFormContext<SourcingScenarioFormValues>();

    const { data: inventorySites = [], isLoading } = useInventorySites();

    const preferredInventorySites = useWatch({ control, name: 'solution_preference.preferred_inventory_sites' }) ?? [];
    const approvedInventorySites = useWatch({ control, name: 'solution_preference.approved_inventory_sites' }) ?? [];
    const excludedInventorySites = useWatch({ control, name: 'solution_preference.excluded_inventory_sites' }) ?? [];

    const nonApprovedInventorySites = inventorySites
        .map((site) => site.id)
        .filter(
            (id) =>
                !preferredInventorySites.includes(id) &&
                !approvedInventorySites.includes(id) &&
                !excludedInventorySites.includes(id),
        );

    return {
        preferredInventorySites,
        approvedInventorySites,
        excludedInventorySites,
        nonApprovedInventorySites,
        inventorySites,
        isLoading,
    };
}

const DisplayModeButtonGroup: React.FunctionComponent<{
    displayMode: DisplayMode;
    onClick: (value: DisplayMode) => void;
}> = ({ displayMode, onClick }) => {
    const { preferredInventorySites, approvedInventorySites, excludedInventorySites, nonApprovedInventorySites } =
        useWatchInventorySites();

    return (
        <ButtonGroup size={'large'}>
            <ButtonGroupItem
                selected={displayMode === 'approved'}
                onClick={() => onClick('approved')}
                count={new Set(preferredInventorySites.concat(approvedInventorySites)).size}
            >
                <Trans>Preferred and approved</Trans>
            </ButtonGroupItem>
            <ButtonGroupItem
                selected={displayMode === 'excluded'}
                onClick={() => onClick('excluded')}
                count={excludedInventorySites.length}
            >
                <Trans>Excluded</Trans>
            </ButtonGroupItem>
            <ButtonGroupItem
                selected={displayMode === 'notApproved'}
                onClick={() => onClick('notApproved')}
                count={nonApprovedInventorySites.length}
            >
                <Trans>Not approved</Trans>
            </ButtonGroupItem>
        </ButtonGroup>
    );
};

const PrefernceTableHeader: React.FunctionComponent<{
    displayMode: DisplayMode;
    selectionIds: string[];
    selectionStatus: SelectionStatus;
    toggleSelection: () => void;
    onClick: (displayMode: DisplayMode) => void;
    disabled: boolean;
}> = ({ displayMode, selectionIds, selectionStatus, toggleSelection, onClick, disabled }) => {
    return (
        <Flexbox padding={'8px 12px'} gap={'8px'} bgcolor={colorSystem.neutral[0]} alignItems={'center'}>
            <Checkbox
                size="small"
                checked={selectionStatus === 'all'}
                indeterminate={selectionStatus === 'indeterminate'}
                style={{ visibility: disabled ? 'hidden' : 'visible' }}
                onClick={toggleSelection}
            />
            <Text variant="h5">
                <Trans>Inventory site</Trans>
            </Text>
            <Flexbox flex={1} />
            {!disabled && (
                <Flexbox gap={6} alignItems={'center'}>
                    <Text variant="body-small">{t`Move ${selectionIds.length} selected to:`}</Text>
                    {displayMode !== 'approved' && (
                        <SecondaryButton
                            size={'small'}
                            disabled={selectionStatus === 'none'}
                            onClick={() => onClick('approved')}
                        >{t`Approved`}</SecondaryButton>
                    )}
                    {displayMode !== 'excluded' && (
                        <SecondaryButton
                            size={'small'}
                            disabled={selectionStatus === 'none'}
                            onClick={() => onClick('excluded')}
                        >{t`Excluded`}</SecondaryButton>
                    )}
                    {displayMode !== 'notApproved' && (
                        <SecondaryButton
                            size={'small'}
                            disabled={selectionStatus === 'none'}
                            onClick={() => onClick('notApproved')}
                        >{t`Not approved`}</SecondaryButton>
                    )}
                </Flexbox>
            )}
        </Flexbox>
    );
};

const PreferredInventorySiteInfo: React.FunctionComponent<{
    inventorySite: SiteDTO;
    preferredInventorySites: string[];
    disabled: boolean;
    togglePreferred: (id: string) => void;
}> = ({ inventorySite, preferredInventorySites, disabled, togglePreferred }) => {
    const isPreferred = preferredInventorySites.includes(inventorySite.id);

    if (isPreferred) {
        return (
            <TertiaryButton
                size={'small'}
                disabled={disabled}
                onClick={() => togglePreferred(inventorySite.id)}
                endIcon={<StarRounded style={{ color: colorSystem.primary[7] }} />}
            >
                <Text variant={'body-small'} color={colorSystem.primary[7]}>{t`Preferred`}</Text>
            </TertiaryButton>
        );
    }

    if (!disabled) {
        return (
            <TertiaryButton
                size={'small'}
                disabled={disabled}
                onClick={() => togglePreferred(inventorySite.id)}
                endIcon={<StarBorderRounded style={{ color: colorSystem.primary[7] }} />}
            >
                <></>
            </TertiaryButton>
        );
    }
    return <></>;
};

function getItemDataAndSelectionStatus({
    tableDisplayMode,
    approvedInventorySites,
    preferredInventorySites,
    excludedInventorySites,
    nonApprovedInventorySites,
    selectionIds,
    inventorySites,
    query = '',
    comparator,
}: {
    tableDisplayMode: DisplayMode;
    preferredInventorySites: string[];
    approvedInventorySites: string[];
    excludedInventorySites: string[];
    nonApprovedInventorySites: string[];
    selectionIds: string[];
    inventorySites: SiteDTO[];
    query: string | null;
    comparator: Comparable<string>;
}): { filteredItems: string[]; selectionStatus: SelectionStatus } {
    let itemData: string[] = [];
    let selectionStatus: SelectionStatus = 'none';

    switch (tableDisplayMode) {
        case 'approved':
            itemData = uniqueBy(approvedInventorySites.concat(preferredInventorySites), (s) => s);
            break;
        case 'notApproved':
            itemData = nonApprovedInventorySites;
            break;
        case 'excluded':
            itemData = excludedInventorySites;
            break;
        default:
            assertUnreachable(tableDisplayMode);
    }

    switch (selectionIds.length) {
        case 0:
            selectionStatus = 'none';
            break;
        case itemData.length:
            selectionStatus = 'all';
            break;
        default:
            selectionStatus = 'indeterminate';
            break;
    }

    const filteredIds = inventorySites
        .filter((s) => !isPresent(query) || formatInventorySite(s).toLowerCase().includes(query.toLowerCase().trim()))
        .map((s) => s.id);

    const filteredItems = itemData.filter((s) => filteredIds.includes(s)).sort(comparator);

    return { filteredItems, selectionStatus };
}

const InventorySitePreferenceTable: React.FunctionComponent<{
    displayMode: DisplayMode;
    tableDisplayMode: DisplayMode;
    disabled: boolean;
    query: string | null;
}> = ({ displayMode, tableDisplayMode, disabled, query }) => {
    const { setValue, formState } = useFormContext<SourcingScenarioFormValues>();
    const [selectionIds, setSelectionIds] = React.useState<string[]>([]);

    const {
        preferredInventorySites,
        approvedInventorySites,
        excludedInventorySites,
        nonApprovedInventorySites,
        inventorySites,
        isLoading,
    } = useWatchInventorySites();
    const initiallyPreferred =
        formState.defaultValues?.solution_preference?.preferred_inventory_sites?.filter(isPresent) ??
        preferredInventorySites;

    const comparator = createComparatorByIndex(inventorySites, initiallyPreferred);

    const { filteredItems, selectionStatus } = getItemDataAndSelectionStatus({
        tableDisplayMode,
        selectionIds,
        query,
        comparator,
        inventorySites,
        preferredInventorySites,
        approvedInventorySites,
        excludedInventorySites,
        nonApprovedInventorySites,
    });

    // When switching the disabled flag, clear the selection state
    React.useEffect(() => {
        setSelectionIds([]);
    }, [disabled]);

    const toggleSelection = React.useCallback(() => {
        if (selectionIds.length === 0) {
            setSelectionIds(filteredItems);
        } else {
            setSelectionIds([]);
        }
    }, [selectionIds, setSelectionIds, filteredItems]);

    const onSelect = React.useCallback(
        (value: string) => {
            setSelectionIds((state) => (state.includes(value) ? state.filter((v) => v !== value) : [...state, value]));
        },
        [setSelectionIds],
    );

    const togglePreferred = (id: string) => {
        if (preferredInventorySites.includes(id)) {
            setValue(
                'solution_preference.preferred_inventory_sites',
                preferredInventorySites.filter((item) => item !== id),
            );
            setValue('solution_preference.approved_inventory_sites', [...approvedInventorySites, id]);
        } else {
            setValue(
                'solution_preference.approved_inventory_sites',
                approvedInventorySites.filter((item) => item !== id),
            );
            setValue('solution_preference.preferred_inventory_sites', [...preferredInventorySites, id]);
        }
    };

    const handleMoveTo = (target: DisplayMode) => {
        function isNotSelected(id: string): boolean {
            return !selectionIds.includes(id);
        }
        const selectedPreferred: string[] = [];
        const selectedApproved: string[] = target === 'approved' ? selectionIds : [];
        const selectedExcluded: string[] = target === 'excluded' ? selectionIds : [];

        // First we remove the selected items from the current shown list
        const newPreferred = preferredInventorySites.filter(isNotSelected).concat(selectedPreferred).sort(comparator);
        const newApproved = approvedInventorySites.filter(isNotSelected).concat(selectedApproved).sort(comparator);
        const newExcluded = excludedInventorySites.filter(isNotSelected).concat(selectedExcluded).sort(comparator);

        setValue('solution_preference.approved_inventory_sites', newApproved);
        setValue('solution_preference.preferred_inventory_sites', newPreferred);
        setValue('solution_preference.excluded_inventory_sites', newExcluded);

        setSelectionIds([]);
    };

    const InventorySiteRow = ({
        index,
        style,
        data,
    }: {
        index: number;
        style: React.CSSProperties;
        data: string[];
    }) => {
        const value = data[index];
        const inventorySite = inventorySites.find((item) => item.id === value);

        if (!isPresent(inventorySite)) {
            return <></>;
        }

        return (
            <Flexbox
                style={{ boxSizing: 'border-box', ...style }}
                alignItems={'center'}
                borderTop={`1px solid ${colorSystem.neutral[2]}`}
                paddingX={'12px'}
                gap={'8px'}
            >
                <Checkbox
                    size="small"
                    onClick={() => onSelect(value)}
                    checked={selectionIds.includes(value)}
                    style={{ visibility: disabled ? 'hidden' : 'visible' }}
                />

                <Text variant={'body-small'}>{formatInventorySite(inventorySite)}</Text>

                <Flexbox flex={1} />
                {tableDisplayMode === 'approved' && (
                    <PreferredInventorySiteInfo
                        inventorySite={inventorySite}
                        preferredInventorySites={preferredInventorySites}
                        togglePreferred={togglePreferred}
                        disabled={disabled}
                    />
                )}
            </Flexbox>
        );
    };

    return (
        <Box
            style={{
                border: `1px solid ${colorSystem.neutral[2]}`,
                borderRadius: '8px',
                // This ensure that the scroll position is persisted while switching between view modes
                display: displayMode === tableDisplayMode ? undefined : 'none',
                overflow: 'hidden',
            }}
        >
            <PrefernceTableHeader
                displayMode={displayMode}
                selectionIds={selectionIds}
                selectionStatus={selectionStatus}
                toggleSelection={toggleSelection}
                onClick={handleMoveTo}
                disabled={disabled}
            />
            {isLoading ? (
                <CenteredLayout height={180}>
                    <CircularProgress />
                </CenteredLayout>
            ) : filteredItems.length === 0 && !isPresent(query) ? (
                <CenteredLayout height={180}>
                    <Text color={colorSystem.neutral[7]}>
                        <Trans>No site in this category</Trans>
                    </Text>
                </CenteredLayout>
            ) : filteredItems.length === 0 && isPresent(query) ? (
                <CenteredLayout height={180}>
                    <Text color={colorSystem.neutral[7]}>
                        <Trans>Sorry, your filter produced no results</Trans>
                    </Text>
                </CenteredLayout>
            ) : (
                <FixedSizeList
                    itemData={filteredItems}
                    itemCount={filteredItems.length}
                    width="100%"
                    height={180}
                    itemSize={32}
                    overscanCount={5}
                >
                    {InventorySiteRow}
                </FixedSizeList>
            )}
        </Box>
    );
};

export const InventorySitePreferenceSection: React.FunctionComponent<{ disabled: boolean }> = ({ disabled }) => {
    const [displayMode, setVieMode] = React.useState<DisplayMode>('approved');
    const [query, setQuery] = React.useState<string | null>(null);

    const handleDisplayModeChange = React.useCallback(
        (displayMode: DisplayMode) => {
            setVieMode(displayMode);
        },
        [setVieMode],
    );

    return (
        <Flexbox flexDirection="column" gap={12} style={{ width: '800px' }}>
            <Text variant="h4">
                <Trans>Inventory site preference</Trans>
            </Text>
            <DisplayModeButtonGroup displayMode={displayMode} onClick={handleDisplayModeChange} />
            <FieldText
                type="search"
                value={query}
                onChange={(value) => setQuery(value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search style={{ color: colorSystem.neutral[6] }} />
                        </InputAdornment>
                    ),
                }}
            />
            {/* We render all tables at once, but only one is visible at a time */}
            <InventorySitePreferenceTable
                displayMode={displayMode}
                tableDisplayMode={'approved'}
                disabled={disabled}
                query={query}
            />
            <InventorySitePreferenceTable
                displayMode={displayMode}
                tableDisplayMode={'excluded'}
                disabled={disabled}
                query={query}
            />
            <InventorySitePreferenceTable
                displayMode={displayMode}
                tableDisplayMode={'notApproved'}
                disabled={disabled}
                query={query}
            />
        </Flexbox>
    );
};
