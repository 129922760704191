import { t, Trans } from '@lingui/macro';
import { assertUnreachable, isPresent } from '@luminovo/commons';
import { colorSystem, PrimaryButton, Text } from '@luminovo/design-system';
import { CustomerPortalAssemblyState, CustomerPortalState, RfqStatus } from '@luminovo/http-client';
import { CircularProgress, styled } from '@mui/material';
import { useIsFetching } from '@tanstack/react-query';
import { httpQueryKey } from '../../../../../resources/http/httpQueryKey';
import { ButtonRequestQuotation } from '../../../ButtonRequestQuotation';
import { convertToIssueLine } from '../CustomerDashboardSteps/BomStep';
import { StepType, useUploadButtonAndDialog } from '../CustomerDashboardSteps/StepContainer';
import { BannerCard } from './BannerCard';
import { CONTAINER_DIMESION, IconContainer } from './IconContainer';
import { TechnicalSupportButton } from './TechnicalSupportButton';

interface CardStatusNextStep {
    type: 'NextStep';
    progressInPercent: number;
    stepType: StepType;
}

interface CardStatusIssues {
    type: 'Issues';
    progressInPercent: number;
    count: number;
    link: string | undefined;
}

interface CardStatusDone {
    type: 'Done';
    progressInPercent: number;
    isSourcingPending: boolean;
}

type CardStatus = CardStatusNextStep | CardStatusIssues | CardStatusDone;

const statusIsDoneOrInProgress = ({ type }: { type: 'Pending' | 'InProgress' | 'Done' }): boolean => {
    return type === 'InProgress' || type === 'Done';
};

export const canRequestTechnicalSupport = (state: CustomerPortalState): boolean => {
    return Object.values(state.assembly_states).every((assemblyState) =>
        canRequestTechnicalSupportForAssembly(assemblyState),
    );
};

export const canRequestTechnicalSupportForAssembly = (state: CustomerPortalAssemblyState): boolean => {
    const isBomReady = state.bom.requirement === 'Required' ? statusIsDoneOrInProgress(state.bom.status) : true;
    const isPcbReady = state.pcb.requirement === 'Required' ? statusIsDoneOrInProgress(state.pcb.status) : true;
    const isCadReady = state.cad.requirement === 'Required' ? statusIsDoneOrInProgress(state.cad.status) : true;
    const isManufacturingFilesReady =
        state.manufacturing_files.requirement === 'Required'
            ? statusIsDoneOrInProgress(state.manufacturing_files.status)
            : true;
    const isManufacturingReady =
        state.manufacturing.requirement === 'Required' ? statusIsDoneOrInProgress(state.manufacturing.status) : true;

    return isBomReady && isPcbReady && isCadReady && isManufacturingFilesReady && isManufacturingReady;
};

const computeProgressInPercent = (state: CustomerPortalState): number => {
    // If there are no mandatory steps in order to get a quotation
    // we display the progress as 100%
    const hasNoRequiredSteps = Object.values(state.assembly_states).every((assemblyState) => {
        return Object.values(assemblyState)
            .map((step) => step.requirement)
            .every((requirement) => requirement !== 'Required');
    });
    if (hasNoRequiredSteps) {
        return 100;
    }

    const { progress, totalSteps } = Object.values(state.assembly_states)
        .map((assemblyState) => {
            const steps = Object.values(assemblyState);
            const progress = steps
                .filter((step) => !(step.requirement === 'Optional' && step.status.type === 'Pending'))
                .map((step): number => {
                    if (step.status.type === 'Done') {
                        return 1;
                    }
                    if (step.status.type === 'InProgress') {
                        return 0.5;
                    }
                    return 0;
                })
                .reduce((a, b) => a + b, 0);
            const totalSteps = steps.length;

            return {
                progress,
                totalSteps,
            };
        })
        .reduce((a, b) => ({ progress: a.progress + b.progress, totalSteps: a.totalSteps + b.totalSteps }), {
            progress: 0,
            totalSteps: 0,
        });

    return (100 * progress) / totalSteps;
};

const nextPendingRequiredStep = (state: CustomerPortalState): StepType | undefined => {
    for (const assemblyState of Object.values(state.assembly_states)) {
        if (assemblyState.bom.status.type === 'Pending' && assemblyState.bom.requirement === 'Required') {
            return 'bom';
        }

        if (assemblyState.pcb.status.type === 'Pending' && assemblyState.pcb.requirement === 'Required') {
            return 'pcb';
        }

        if (assemblyState.cad.status.type === 'Pending' && assemblyState.cad.requirement === 'Required') {
            return 'cad';
        }

        if (assemblyState.pnp.status.type === 'Pending' && assemblyState.pnp.requirement === 'Required') {
            return 'pnp';
        }

        if (
            assemblyState.manufacturing.status.type === 'Pending' &&
            assemblyState.manufacturing.requirement === 'Required'
        ) {
            return 'manufacturing';
        }

        // We ignore the 'sourcing' step for now
    }

    return undefined;
};

const collectIssues = (
    state: CustomerPortalState,
    rfqId: string,
    assemblyId: string,
): { count: number; link: string | undefined } | undefined => {
    const { count, link } = Object.values(state.assembly_states)
        .map((assemblyState) => {
            const bomIssues =
                assemblyState.bom.status.type === 'InProgress' ? assemblyState.bom.status.issues : undefined;
            const pcbIssues = assemblyState.pcb.status.type === 'InProgress' ? assemblyState.pcb.status.issues : [];

            const pcbIssueCount = pcbIssues.length;
            const issueLines = isPresent(bomIssues) ? convertToIssueLine({ issues: bomIssues, rfqId, assemblyId }) : [];
            const link = issueLines.length > 0 ? issueLines[0].link : undefined;

            return {
                count: pcbIssueCount + issueLines.length,
                link,
            };
        })
        .reduce((a, b) => ({ count: a.count + b.count, link: a.link }), { count: 0, link: undefined });

    if (count === 0 && link === undefined) {
        return undefined;
    }

    return { count, link };
};

const getCardStatus = (state: CustomerPortalState, rfqId: string, assemblyId: string): CardStatus => {
    const progressInPercent = computeProgressInPercent(state);
    const nextStep = nextPendingRequiredStep(state);

    if (isPresent(nextStep)) {
        return {
            type: 'NextStep',
            progressInPercent,
            stepType: nextStep,
        };
    }

    const issues = collectIssues(state, rfqId, assemblyId);
    if (isPresent(issues)) {
        return {
            type: 'Issues',
            progressInPercent,
            count: issues.count,
            link: issues.link,
        };
    }

    const isSourcingPending =
        state.sourcing_state.requirement === 'Required' && state.sourcing_state.status.type !== 'Done';
    return {
        type: 'Done',
        progressInPercent,
        isSourcingPending,
    };
};

const BANNER_BACKGROUND = colorSystem.neutral[9];

export const BannerCardRequestInDraft = ({
    rfqId,
    assemblyId,
    isRfqEditable,
    customerPortalState,
}: {
    rfqId: string;
    assemblyId: string;
    isRfqEditable: boolean;
    customerPortalState: CustomerPortalState;
}): JSX.Element => {
    const isLoadingRequestQuotation =
        useIsFetching({
            queryKey: httpQueryKey('PATCH /rfqs/:rfqId', {
                pathParams: { rfqId },

                requestBody: {
                    type: 'Ems',

                    data: { status: RfqStatus.QuotationInProgress, shipping_tracking_link: null },
                },
            }),
        }) > 0;

    const title = (
        <Trans>
            Your request is still <span style={{ fontWeight: 600 }}>in draft</span>.
        </Trans>
    );

    const requestTechnicalSupportButton = canRequestTechnicalSupport(customerPortalState) ? (
        <TechnicalSupportButton rfqId={rfqId} />
    ) : undefined;

    const cardStatus = getCardStatus(customerPortalState, rfqId, assemblyId);

    const { type } = cardStatus;
    switch (type) {
        case 'NextStep':
            return (
                <BannerCardNextStep
                    cardStatus={cardStatus}
                    requestTechnicalSupportButton={requestTechnicalSupportButton}
                    title={title}
                    isRfqEditable={isRfqEditable}
                    rfqId={rfqId}
                    assemblyId={assemblyId}
                />
            );
        case 'Issues':
            return (
                <BannerCard
                    background={BANNER_BACKGROUND}
                    callToAction={
                        cardStatus.link ? (
                            <StyledPrimaryButton
                                href={cardStatus.link}
                                disabled={isLoadingRequestQuotation || !isRfqEditable}
                            >
                                <Trans>Resolve</Trans>
                            </StyledPrimaryButton>
                        ) : undefined
                    }
                    secondaryAction={requestTechnicalSupportButton}
                    title={title}
                    description={isRfqEditable ? t`Complete all steps before you can request a quotation.` : ''}
                    icon={
                        <CircularProgressView
                            progressInPercent={cardStatus.progressInPercent}
                            isLoading={isLoadingRequestQuotation}
                        />
                    }
                />
            );
        case 'Done':
            const isSourcingPending = cardStatus.isSourcingPending;
            const description = isSourcingPending
                ? t`We are still looking for the best offers to source the parts.`
                : isRfqEditable
                  ? t`You are ready to request a quotation.`
                  : '';

            return (
                <BannerCard
                    background={BANNER_BACKGROUND}
                    callToAction={<ButtonRequestQuotation rfqId={rfqId} customerPortalState={customerPortalState} />}
                    title={title}
                    description={description}
                    icon={
                        <CircularProgressView
                            progressInPercent={cardStatus.progressInPercent}
                            isLoading={isLoadingRequestQuotation}
                        />
                    }
                />
            );
        default:
            assertUnreachable(type);
    }
};

const BannerCardNextStep = ({
    cardStatus,
    requestTechnicalSupportButton,
    title,
    isRfqEditable,
    rfqId,
    assemblyId,
}: {
    cardStatus: CardStatusNextStep;
    requestTechnicalSupportButton: JSX.Element | undefined;
    title: JSX.Element;
    isRfqEditable: boolean;
    rfqId: string;
    assemblyId: string;
}): JSX.Element => {
    const { uploadButton, modal } = useUploadButtonAndDialog(cardStatus.stepType, rfqId, assemblyId, isRfqEditable);

    return (
        <BannerCard
            background={BANNER_BACKGROUND}
            callToAction={
                <>
                    {uploadButton}
                    {modal}
                </>
            }
            secondaryAction={requestTechnicalSupportButton}
            title={title}
            description={isRfqEditable ? t`Complete all steps before you can request a quotation.` : ''}
            icon={<CircularProgressView progressInPercent={cardStatus.progressInPercent} isLoading={false} />}
        />
    );
};

const StyledPrimaryButton = styled(PrimaryButton)({
    flex: '0 0 25%',
});

const CircularProgressView = ({
    progressInPercent,
    isLoading,
}: {
    progressInPercent: number;
    isLoading: boolean;
}): JSX.Element => {
    const diameter = CONTAINER_DIMESION;

    if (isLoading) {
        return <CircularProgress size={diameter} />;
    }

    const getCircularProgess = (value: number, color: string | undefined): JSX.Element => {
        return (
            <CircularProgress
                style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    color,
                }}
                variant="determinate"
                size={diameter}
                value={value}
            />
        );
    };

    return (
        <IconContainer>
            {getCircularProgess(100, colorSystem.neutral[8])}
            {getCircularProgess(progressInPercent, undefined)}
            <Text
                style={{
                    fontSize: 42,
                    color: colorSystem.neutral.white,
                }}
            >
                {Math.floor(progressInPercent)}%
            </Text>
        </IconContainer>
    );
};
