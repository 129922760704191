import { Trans } from '@lingui/macro';
import { PrimaryButton, useNavigate } from '@luminovo/design-system';
import { CustomerPortalState } from '@luminovo/http-client';
import React from 'react';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { useMutationRequestQuotation, useRfQ } from '../../resources/rfq/rfqHandler';
import { id } from '../../utils/ids';
import { useIsRfqEditable } from '../../utils/rfqUtils';
import { route } from '../../utils/routes';
import {
    isEveryCustomerPortalStepDone,
    isEveryCustomerPortalStepDoneAndNoPcbPriceAvailable,
    isEveryOemStepDone,
} from './CustomerDashboard/util/DashboardSteps';
export function ButtonRequestQuotation({
    rfqId,
    customerPortalState,
    overrides,
}: {
    rfqId: string;
    customerPortalState: CustomerPortalState;
    overrides?: { Button: React.ComponentType };
}): JSX.Element {
    const navigate = useNavigate();
    const { Button } = overrides ?? { Button: PrimaryButton };
    const { data: rfq } = useRfQ(rfqId);
    const status = rfq?.status;
    const { isRfqEditable } = useIsRfqEditable(status, rfq?.is_archived, rfq?.workflow_type);
    const { mutateAsync: requestQuotation, isPending: isLoadingRequestQuotation } = useMutationRequestQuotation(rfqId);

    const canRequestQuotation = useCanRequestQuotation(rfqId, customerPortalState);

    if (!isRfqEditable || !rfq || !canRequestQuotation) {
        return <></>;
    }

    return (
        <Button
            id={id('oem_dashboard/button_request_quotation')}
            disabled={isLoadingRequestQuotation}
            onClick={async () => {
                await requestQuotation({ pathParams: { rfqId } });
                navigate(route('/rfqs/:rfqId/dashboard', { rfqId }));
            }}
        >
            <Trans>Request quotation</Trans>
        </Button>
    );
}

const useCanRequestQuotation = (rfqId: string, state: CustomerPortalState): boolean => {
    const { data: rfq, isLoading } = useHttpQuery('GET /rfqs/:rfqId', { pathParams: { rfqId } });

    if (!rfq || isLoading) {
        return false;
    }

    const isManualWorkflow = rfq.data.workflow_type === 'Manual';
    /* eslint-disable-next-line spellcheck/spell-checker */
    const isWorkflowTypeManualOrDontShow = isManualWorkflow || rfq.data.workflow_type === 'DontShow';
    const areAllStepsDone = isEveryCustomerPortalStepDone(state);
    const allOemStepsDone = isEveryOemStepDone(state);

    const isAutomaticWorkflow = rfq.data.workflow_type === 'Automatic';
    const hasNoPcbOffer = isEveryCustomerPortalStepDoneAndNoPcbPriceAvailable(state);

    // if backend has somehow kept the status at RequestInDraft, we should always show this button when all oem steps
    // are done regardless of the workflow type
    if (
        (isAutomaticWorkflow && hasNoPcbOffer) ||
        (isWorkflowTypeManualOrDontShow && areAllStepsDone) ||
        allOemStepsDone
    ) {
        return true;
    }

    return false;
};
