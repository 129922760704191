/* eslint-disable spellcheck/spell-checker */

interface AuthTheme {
    primaryColor: string;
    pageBackgroundColor: string;
    logoUrl: string;
}

export interface Tenant extends BasicTenant {
    auth0OrgId: string;
    theme: AuthTheme;
}

interface BasicTenant {
    name: string;
    tenant: string;
    /**
     * If true, the user must have cookies enabled before they can log in.
     *
     * Note: this is a special case for BMK
     */
    requiresCookiesBeforeAuth?: boolean;
}

const adex: Tenant = {
    name: 'Adex Electronique',
    tenant: 'adex',
    auth0OrgId: 'org_jO39RN7EAtKKM3fN',
    theme: {
        primaryColor: '#80f0ff',
        pageBackgroundColor: '#3a4364',
        logoUrl: 'https://i.postimg.cc/6pYmK8rq/Screenshot-2023-12-21-at-7-31-50-PM.png',
    },
};

const zollner: Tenant = {
    name: 'Zollner Rapid Sample Europe',
    auth0OrgId: 'org_heF6w8FXp7hv8SPo',
    tenant: 'zollner',
    theme: {
        primaryColor: '#e30614',
        pageBackgroundColor: '#4c4c4c',
        logoUrl: 'https://www.zollner.de/fileadmin/user_upload/00_Startseite/logo.svg',
    },
};

const zollnerUsa: Tenant = {
    name: 'Zollner USA',
    auth0OrgId: 'org_7LZS3sniFuFkPIsz',
    tenant: 'zollner-usa',
    theme: zollner.theme,
};

const zollnerChina: Tenant = {
    name: 'Zollner China',
    auth0OrgId: 'org_RkGLjCDTkAy6QKcj',
    tenant: 'zollner-china',
    theme: zollner.theme,
};

const zollnerCcp: Tenant = {
    name: 'Zollner CCP',
    auth0OrgId: 'org_EH1ebUmNC7YJXQkr',
    tenant: 'zollner-ccp',
    theme: zollner.theme,
};

const bmk: Tenant = {
    name: 'BMK',
    auth0OrgId: 'org_Lm2vYmpLBQEZIUhf',
    tenant: 'bmk',
    requiresCookiesBeforeAuth: true,
    theme: {
        primaryColor: '#336699',
        pageBackgroundColor: '#121212',
        logoUrl: 'https://www.bmk-group.de/fileadmin/templates/images/bmk-logo@2x.png',
    },
};

const connectgroup: Tenant = {
    name: 'Connect Group',
    tenant: 'connectgroup',
    auth0OrgId: 'org_CKxX5cyjCdnpsZRB',
    theme: {
        primaryColor: '#437EC4',
        pageBackgroundColor: '#000000',
        logoUrl: 'https://www.connectgroup.com/images/logo-connectgroup-blue.svg',
    },
};

const emsproto: Tenant = {
    name: 'EMS Proto',
    tenant: 'emsproto',
    auth0OrgId: 'org_HmwuZxzOabdNT6Pn',
    theme: {
        primaryColor: '#000000',
        pageBackgroundColor: '#D1D1D1',
        logoUrl:
            'https://cdn.prod.website-files.com/66c63499307332e239053ffd/66e1c8aed717fd97532c1e6b_emsproto_logo.svg',
    },
};

const globalComponents: Tenant = {
    name: 'Global Components',
    auth0OrgId: 'org_PMOVmuKUOtrLVJvv',
    tenant: 'global-components',
    theme: {
        primaryColor: '#FF290D',
        pageBackgroundColor: '#02003D',
        logoUrl: 'https://www.global-components.de/files/cto_layout/themedesigner/uploads/logo-global-components.svg',
    },
};

const gpbTheme = {
    primaryColor: '#198754',
    pageBackgroundColor: '#f7f7f7',
    logoUrl: 'https://i.ibb.co/Lz9xbPQD/Organization-logo.png',
};

const gpv: Tenant = {
    name: 'GPV',
    tenant: 'gpv',
    auth0OrgId: 'org_1cd49gIixZWzzLIU',
    theme: gpbTheme,
};

const pcbWhizApac: Tenant = {
    name: 'pcbwhiz APAC',
    auth0OrgId: 'org_rr51XzK1eKV4t2jW',
    tenant: 'pcbwhiz-apac',
    theme: {
        primaryColor: '#FF7937',
        pageBackgroundColor: '#000000',
        logoUrl: 'https://www.pcbwhiz.com/logos/logo_fitting.png',
    },
};

const pcbWhizEu: Tenant = {
    name: 'pcbwhiz EU',
    auth0OrgId: 'org_W3wXu4ATrNApbsT9',
    tenant: 'pcbwhiz-eu',
    theme: pcbWhizApac.theme,
};

const kessler: Tenant = {
    name: 'Kessler',
    auth0OrgId: 'org_JWyvB2dvhAe4iCon',
    tenant: 'kessler',
    theme: {
        primaryColor: '#ea602b',
        pageBackgroundColor: '#061c22',
        logoUrl:
            'https://uploads-ssl.webflow.com/62309ce2505c9a217be1de25/62309f23f06d7a53de1062aa_positive-logo-kessler-group.svg',
    },
};

const tenants: Record<string, Tenant> = {
    // 'ci-master.dev.luminovo.ai': {
    //     name: '🚀 CI Master 🚀',
    //     auth0OrgId: 'org_Rnq9vlAkXrBbBucT',
    // },
    // 'localhost:3000': {
    //     name: 'Localhost',
    //     auth0OrgId: 'org_Rnq9vlAkXrBbBucT',
    //     tenant: 'luminovo',
    //     theme: [
    //         {
    //             primaryColor: '#6a2eb5',
    //             pageBackgroundColor: '#ccdefc', // this is #00bcd4
    //             logoUrl: 'https://placehold.co/600x200',
    //         },
    //         kessler.theme,
    //         bmk.theme,
    //         pcbWhizApac.theme,
    //         globalComponents.theme,
    //         connectgroup.theme,
    //         emsproto.theme,
    //         gpv.theme,
    //         pcbWhizEu.theme,
    //     ][Math.floor(Math.random() * 10)],
    // },
    // 'luminovo.luminovo.ai': {
    //     name: 'Luminovo GmbH',
    //     auth0OrgId: 'org_8MsDfjHRdBHh6wgs',
    // },
    'zollner.luminovo.com': zollner,
    'zollner.luminovo.ai': zollner,
    'zollner-usa.luminovo.com': zollnerUsa,
    'zollner-usa.luminovo.ai': zollnerUsa,
    'zollner-china.luminovo.com': zollnerChina,
    'zollner-china.luminovo.ai': zollnerChina,
    'zollner-ccp.luminovo.com': zollnerCcp,
    'bmk.luminovo.com': bmk,
    'bmk.luminovo.ai': bmk,
    'portal.bmkyourproto.de': bmk,
    'pcbwhiz-eu.luminovo.com': pcbWhizEu,
    'pcbwhiz-eu.luminovo.ai': pcbWhizEu,
    'app-eu.pcbwhiz.com': pcbWhizEu,
    'pcbwhiz-apac.luminovo.ai': pcbWhizApac,
    'pcbwhiz-apac.luminovo.com': pcbWhizApac,
    'app-apac.pcbwhiz.com': pcbWhizApac,
    'kessler.luminovo.com': kessler,
    'kessler.luminovo.ai': kessler,
    'emsproto.luminovo.com': emsproto,
    'emsproto.luminovo.ai': emsproto,
    'global-components.luminovo.com': globalComponents,
    'global-components.luminovo.ai': globalComponents,
    'adex.luminovo.com': adex,
    'adex.luminovo.ai': adex,
    'adex-electronique.luminovo.com': adex,
    'adex-electronique.luminovo.ai': adex,
    'portail.adex-electronique.com': adex,
    'connectgroup.luminovo.com': connectgroup,
    'connectgroup.luminovo.ai': connectgroup,
    'kirron.luminovo.com': {
        name: 'Kirron',
        tenant: 'kirron',
        auth0OrgId: 'org_Z9atvhbZJAaBrsek',
        theme: {
            logoUrl:
                'https://static.wixstatic.com/media/9bf47a_b7d6b6a0032048ef95bd1db91a2ef6f2~mv2.png/v1/fill/w_356,h_122,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/KIRRON_EMS.png',
            primaryColor: '#A6C7E9',
            pageBackgroundColor: '#f7f7f7',
        },
    },
    'bomanalyzer24.com': {
        name: 'Kirron',
        tenant: 'kirron',
        auth0OrgId: 'org_Z9atvhbZJAaBrsek',
        theme: {
            logoUrl:
                'https://static.wixstatic.com/media/9bf47a_b7d6b6a0032048ef95bd1db91a2ef6f2~mv2.png/v1/fill/w_356,h_122,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/KIRRON_EMS.png',
            primaryColor: '#A6C7E9',
            pageBackgroundColor: '#f7f7f7',
        },
    },
    'gpv.luminovo.com': gpv,
    'gpv.luminovo.ai': gpv,
    'gpv-eee.luminovo.com': {
        name: 'GPV EEE',
        tenant: 'gpv-eee',
        auth0OrgId: 'org_oQHKOtabqzRGOr8Z',
        theme: gpbTheme,
    },
    'gpv-fil.luminovo.com': {
        name: 'GPV FIL',
        tenant: 'gpv-fil',
        auth0OrgId: 'org_drT551DXxRVJn9qf',
        theme: gpbTheme,
    },
    'gpv-skn.luminovo.com': {
        name: 'GPV SKN',
        tenant: 'gpv-skn',
        auth0OrgId: 'org_FdyZ2zKHsVuMi69u',
        theme: gpbTheme,
    },
    'gpv-skh.luminovo.com': {
        name: 'GPV SKH',
        tenant: 'gpv-skh',
        auth0OrgId: 'org_nMBGXoN9wl6gh0Zr',
        theme: gpbTheme,
    },
    'gpv-sev.luminovo.com': {
        name: 'GPV SEV',
        tenant: 'gpv-sev',
        auth0OrgId: 'org_4Mf9TZB4kSvz3SI1',
        theme: gpbTheme,
    },
    'gpv-fastproto.luminovo.com': {
        name: 'GPV FastProto',
        tenant: 'gpv-fastproto',
        auth0OrgId: 'org_tTmPX44OtGKf9ZHX',
        theme: gpbTheme,
    },
    'gpv-chm.luminovo.com': {
        name: 'GPV CHM',
        tenant: 'gpv-chm',
        auth0OrgId: 'org_w2gY8QMSf6MlfsBs',
        theme: gpbTheme,
    },
    'gpv-thb.luminovo.com': {
        name: 'GPV THB',
        tenant: 'gpv-thb',
        auth0OrgId: 'org_mwyoqcyHzdIRrAfk',
        theme: gpbTheme,
    },
    'gpv-nego.luminovo.com': {
        name: 'GPV NEGO',
        tenant: 'gpv-nego',
        auth0OrgId: 'org_Z4gimxbx8ZZ3luqc',
        theme: gpbTheme,
    },
    'gpv-lkk.luminovo.com': {
        name: 'GPV LKK',
        tenant: 'gpv-lkk',
        auth0OrgId: 'org_eQG8rJCjvIYrF2XG',
        theme: gpbTheme,
    },
    'gpv-cns.luminovo.com': {
        name: 'GPV CNS',
        tenant: 'gpv-cns',
        auth0OrgId: 'org_MIxqN4bC7ngKxaq9',
        theme: gpbTheme,
    },
    'gpv-cnb.luminovo.com': {
        name: 'GPV CNB',
        tenant: 'gpv-cnb',
        auth0OrgId: 'org_nJvJ3TX8a3RY4xoP',
        theme: gpbTheme,
    },
    'gpv-deh.luminovo.com': {
        name: 'GPV DEH',
        tenant: 'gpv-deh',
        auth0OrgId: 'org_vCd11wspUQx8LhuW',
        theme: gpbTheme,
    },
    'gpv-mxg.luminovo.com': {
        name: 'GPV MXG',
        tenant: 'gpv-mxg',
        auth0OrgId: 'org_19rRHh8tr7kmelAM',
        theme: gpbTheme,
    },
    'gpv-dka.luminovo.com': {
        name: 'GPV DKA',
        tenant: 'gpv-dka',
        auth0OrgId: 'org_NcvcR5y0qRzDeUy3',
        theme: gpbTheme,
    },

    'mycicor.luminovo.com': {
        name: 'Cicor',
        tenant: 'mycicor',
        auth0OrgId: 'org_wniklemOdaN7nisV',
        theme: {
            logoUrl: 'https://www.cicor.com/typo3conf/ext/koch_theme/Resources/Public/Images/logo.svg',
            primaryColor: '#085ca4',
            pageBackgroundColor: '#000000',
        },
    },
    'bever-elektronik.luminovo.com': {
        name: 'Bever Elektronik',
        tenant: 'bever-elektronik',
        auth0OrgId: 'org_5Wpj3QnvojuFQL28',
        theme: {
            primaryColor: '#3774c3',
            pageBackgroundColor: '#f7f7f7',
            logoUrl:
                'https://cdn.prod.website-files.com/661542edc69107a141433a4d/6615447af4e221a097d212ea_1%20(3)-p-500.png',
        },
    },
};

/**
 * Basic tenants are tenants who have multipe domains that are linked to the same tenant
 * and that do not have a customer portal setup (i.e. they do not have an Auth0 organization).
 */
const basicTenants: Record<string, BasicTenant> = {
    'sga.luminovo.ai': {
        name: 'SG Automotive d.o.o.',
        tenant: 'sg-automotive',
    },
    'sga.luminovo.com': {
        name: 'SG Automotive d.o.o.',
        tenant: 'sg-automotive',
    },
    'sg-automotive.luminovo.ai': {
        name: 'SG Automotive d.o.o.',
        tenant: 'sg-automotive',
    },
    'sg-automotive.luminovo.com': {
        name: 'SG Automotive d.o.o.',
        tenant: 'sg-automotive',
    },
    'selva.luminovo.com': {
        name: 'Selva Electronique',
        tenant: 'selva-electronique',
    },
    'selva.luminovo.ai': {
        name: 'Selva Electronique',
        tenant: 'selva-electronique',
    },
    'bmk-series.luminovo.com': {
        name: 'BMK',
        tenant: 'bmk-series',
        requiresCookiesBeforeAuth: true,
    },
    'bmk-israel.luminovo.com': {
        name: 'BMK',
        tenant: 'bmk-israel',
        requiresCookiesBeforeAuth: true,
    },
    'bmk-sol.luminovo.com': {
        name: 'BMK',
        tenant: 'bmk-sol',
        requiresCookiesBeforeAuth: true,
    },
    'starteam.luminovo.com': {
        name: 'Starteam',
        tenant: 'starteam',
    },
};

export function getCustomerPortalTenant(): Tenant | undefined {
    const tenant = tenants[window.location.host];
    return tenant;
}

/**
 * Basic tenants are tenants who have multipe domains that are linked to the same tenant
 * and that do not have a customer portal setup (i.e. they do not have an Auth0 organization).
 */
function getBasicTenant(): BasicTenant | undefined {
    const tenant = basicTenants[window.location.host];
    return tenant;
}

/**
 * TODO: This is a temporary solution to get the tenant ID.
 */
export function getTenantId() {
    const customerPortalTenant = getCustomerPortalTenant();
    if (customerPortalTenant) {
        return customerPortalTenant.tenant;
    }

    const basicTenant = getBasicTenant();
    if (basicTenant) {
        return basicTenant.tenant;
    }

    if (window.location.host.split('.').some((part) => part === 'dev' || part.includes('localhost'))) {
        return 'luminovo';
    }
    return window.location.host.split('.')[0];
}

/**
 * @deprecated - Only used by BMK, which is a special case for the cookie requirement.
 */
export function getRequiresCookiesBeforeAuth() {
    const tenant = getCustomerPortalTenant() ?? getBasicTenant();
    return Boolean(tenant?.requiresCookiesBeforeAuth);
}

export function getTenantName() {
    return window.location.host.split('.')[0];
}
