import type { IHeaderParams } from 'ag-grid-community';
import { useState } from 'react';
import { HeaderComponentDefault } from './HeaderComponentDefault';
import { MenuItemSetAllValues } from './MenuItemSetAllValues';

interface HeaderComponentEnumProps<TRow, TValue> extends IHeaderParams {
    /**
     * Used for the "set all values" workflow.
     * Only used for enum and boolean columns.
     */
    options?: Array<TValue>;
    onUpdateRow?: (row: TRow, newValue: TValue) => TRow;
}

export function HeaderComponentEnum<TRow, TValue>({
    column,
    api,
    options,
    setSort,
    onUpdateRow,
    ...rest
}: HeaderComponentEnumProps<TRow, TValue>): JSX.Element {
    const [anchorEl, setAnchorEl] = useState<null | Element>(null);

    return (
        <HeaderComponentDefault
            {...rest}
            column={column}
            setSort={setSort}
            api={api}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            additionalMenuItems={
                options &&
                onUpdateRow && (
                    <>
                        <MenuItemSetAllValues
                            column={column}
                            api={api}
                            options={options}
                            onClose={() => setAnchorEl(null)}
                            onUpdateRow={onUpdateRow}
                        />
                    </>
                )
            }
        />
    );
}
