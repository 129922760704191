import { isPresent } from '@luminovo/commons';
import {
    CustomerPortalAssemblyState,
    CustomerPortalRequirement,
    CustomerPortalState,
    PcbState,
} from '@luminovo/http-client';

export const isStepDone = (step: {
    requirement: CustomerPortalRequirement;
    status: { type: 'Pending' | 'InProgress' | 'Done' };
}): boolean => {
    return step.status.type === 'Done' || (step.status.type === 'Pending' && step.requirement === 'Optional');
};

export const isEveryStepDone = (
    state: CustomerPortalState,
    assemblyCheck: (state: CustomerPortalAssemblyState) => boolean,
): boolean => {
    const areAssemblyStepsDone = Object.values(state.assembly_states).every(assemblyCheck);
    const isSourcingDone = isStepDone(state.sourcing_state);
    return areAssemblyStepsDone && isSourcingDone;
};

export const isEveryCustomerPortalStepDone = (state: CustomerPortalState): boolean => {
    return isEveryStepDone(state, isEveryAssemblyStepDone);
};

export const isEveryOemStepDone = (state: CustomerPortalState): boolean => {
    return isEveryStepDone(state, isEveryAssemblyOemStepDone);
};

export const isEveryAssemblyStepDone = (state: CustomerPortalAssemblyState): boolean => {
    return isEveryAssemblyOemStepDone(state) && isStepDone(state.manufacturing);
};

export const isEveryAssemblyOemStepDone = (state: CustomerPortalAssemblyState): boolean => {
    return (
        isStepDone(state.bom) &&
        isStepDone(state.pcb) &&
        isStepDone(state.cad) &&
        isStepDone(state.manufacturing_files) &&
        isStepDone(state.pnp)
    );
};

export const isPcbOfferMissing = (state: PcbState): boolean => {
    const isPcbStateInProgressAndNoOfferAvailable =
        state.status.type === 'InProgress' &&
        (state.requirement === 'Required' || state.requirement === 'Optional') &&
        state.status.issues.length === 1 &&
        isPresent(state.status.issues.find((issue) => issue.type === 'NoOffer'));

    return isPcbStateInProgressAndNoOfferAvailable;
};

export const isEveryCustomerPortalStepDoneAndNoPcbPriceAvailable = (state: CustomerPortalState): boolean => {
    return Object.values(state.assembly_states)
        .map((assemblyState) => isEveryAssemblyStepDoneAndNoPcbPriceAvailable(assemblyState))
        .reduce((a, b) => a && b, true);
};

export const isEveryAssemblyStepDoneAndNoPcbPriceAvailable = (state: CustomerPortalAssemblyState): boolean => {
    return (
        isStepDone(state.bom) &&
        isPcbOfferMissing(state.pcb) &&
        isStepDone(state.cad) &&
        isStepDone(state.manufacturing_files) &&
        isStepDone(state.manufacturing) &&
        isStepDone(state.pnp)
    );
};
